import { Button, Menu, MenuItem } from "@mui/material";
import { useState } from "react";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";

export const MenuPopupButton = <L extends string>({
  actions,
  sx,
}: {
  actions: { labels: L[]; onClick: (selectedAction: L) => void };
  sx?: any;
}) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <Button
        aria-controls="simple-menu"
        aria-haspopup="true"
        onClick={handleClick}
        sx={sx}
      >
        <MoreHorizIcon />
      </Button>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {actions.labels.map((label, index) => (
          <MenuItem
            key={index + 1}
            onClick={() => {
              handleClose();
              actions.onClick(label);
            }}
          >
            {label}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};
