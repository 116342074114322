import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export type StatusMessage =
  | "Please note that it is past - Latest Cancelation Time - for this session and therefore can not be cancelled, pending consultant availability. If you will not be able to attend, you may want to designate another user to participate in the session in your stead so as to make use of this valuable time.."
  | "You have already cancelled your session"
  | "You have already confirmed your session"
  | "Your session has been canceled. Your consultant will be in touch with you to reschedule the session"
  | "Thank you for confirming your session! We look forwarding to talking to you and empowering you!"
  | "";

export interface MainState {
  statusMessage: StatusMessage;
}

const initialState: MainState = {
  statusMessage: "",
};

export const main = {
  ...createSlice({
    name: "main",
    initialState,
    reducers: {
      setStatusMessage(state, { payload }: PayloadAction<StatusMessage>) {
        state.statusMessage = payload;
      },
    },
  }),
  persist: true,
};
