import { InputAdornment, IconButton, TextField } from "@mui/material";
import SendIcon from "@mui/icons-material/Send";
import { useState } from "react";

interface SendMessageBarProps {
  sendMessage: (message: string) => void;
  disabled: boolean;
  sx?: any;
}

export const SendMessageBar = ({
  sx,
  sendMessage,
  disabled,
}: SendMessageBarProps) => {
  const [input, setInput] = useState("");
  const handleSend = (event) => {
    event.preventDefault();
    sendMessage(input);
    setInput("");
  };
  return (
    <TextField
      value={input}
      onChange={(event) => setInput(event.target.value)}
      variant="outlined"
      label="Ask a question"
      margin="normal"
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <IconButton
              type="submit"
              onClick={handleSend}
              disabled={disabled || !input}
            >
              <SendIcon />
            </IconButton>
          </InputAdornment>
        ),
      }}
      onKeyDown={(event) => {
        if (event.key === "Enter" && !event.shiftKey && !disabled && input) {
          handleSend(event);
        }
      }}
      sx={{ width: "55%", ...sx }}
    />
  );
};
