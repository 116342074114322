import { useDispatch, adminActions, adminSelectors } from "../../state";
import { DISCUSSIONS_COLUMNS } from "../../components/tables/columns";
import GenericSSPPage from "../../components/tables/GenericServerSidePage";
import { Button } from "@mui/material";
import { ActionBox } from "./GenericPage.styles";

export default function DiscussionsPage() {
  const dispatch = useDispatch();
  return (
    <>
      <ActionBox>
        <Button
          type="submit"
          onClick={() => {
            dispatch(adminActions.syncDiscussions());
          }}
          variant="contained"
          color="primary"
        >
          Sync Discussions
        </Button>
      </ActionBox>
      <GenericSSPPage
        adminAction={adminActions.getDiscussions}
        rowsSelector={adminSelectors.discussions}
        dataDisplayName="Discussions"
        columns={DISCUSSIONS_COLUMNS}
      />
    </>
  );
}
