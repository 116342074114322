import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { User } from "../types";

export interface Role {
  id: number;
  internal_name: string;
  name: string;
  createdAt: string;
  updatedAt?: any;
  deletedAt?: any;
  user_roles: {
    role_id: number;
  };
}

export interface AuthState {
  dealerId?: number;
  roles?: Role[];
  token?: string;
  userId?: number;
  userName?: string;
  user?: User;
  requiresMfa?: Boolean;
}

const initialState: AuthState = {};

export const auth = {
  ...createSlice({
    name: "auth",
    initialState,
    reducers: {
      setAuthState(state, { payload }: PayloadAction<AuthState | undefined>) {
        if (!payload) {
          return initialState;
        }
        Object.assign(state, payload);
      },
    },
  }),
  persist: true,
};
