import { useEffect } from "react";
import { DataGrid, GridColDef, GridRenderCellParams } from "@mui/x-data-grid";
import { useDispatch, useSelector } from "react-redux";
import { adminActions, adminSelectors } from "../../state";
import { Link } from "react-router-dom";
import AdminPages from ".";
import { Button, IconButton } from "@mui/material";
import { AddIcon, ConfirmDeleteDialog } from "../../components";
import { ActionBox, RootListViewStyled } from "./GenericPage.styles";
import { Create } from "@mui/icons-material";
import { format } from "date-fns";
import { useMobile } from "../../themes";

export const RolesPage = () => {
  const dispatch = useDispatch();
  const users = useSelector(adminSelectors.users);
  const isMobile = useMobile();
  const columns: GridColDef[] = [
    { field: "id", headerName: "Id", width: 150 },
    { field: "first_name", headerName: "First Name", width: 150 },
    { field: "last_name", headerName: "Last Name", width: 150 },
    { field: "email", headerName: "Email", width: 200 },
    { field: "login_attempts", headerName: "Login Count", width: 120 },
    {
      field: "last_login",
      headerName: "Last Login",
      width: 180,
      valueFormatter: (v) =>
        v && typeof v.value === "string" ? format(new Date(v.value), "Pp") : "",
    },
    { field: "last_failed_login", headerName: "Last Failed Login", width: 150 },
    {
      field: "roles",
      headerName: "Roles",
      width: 200,
      renderCell: (params) => params.value.map((r) => r.name).join(", "),
    },
    {
      field: "actions",
      headerName: "Actions",
      width: 150,
      renderCell: (params: GridRenderCellParams<number>) => (
        <>
          <Link
            to={AdminPages.editUsers.path.replace(":id", params.id.toString())}
          >
            <IconButton color="primary" size="small">
              <Create />
            </IconButton>
          </Link>

          <ConfirmDeleteDialog
            handleConfirm={() => dispatch(adminActions.deleteUser(params.id))}
          />
        </>
      ),
    },
  ];
  useEffect(() => {
    dispatch(adminActions.getUsers(""));
  }, [dispatch]);

  return (
    <RootListViewStyled>
      <ActionBox>
        <Link
          style={{ width: isMobile ? "100%" : undefined }}
          to={AdminPages.createUsers.path}
        >
          <Button fullWidth variant="outlined" color="primary">
            <AddIcon /> Add User
          </Button>
        </Link>
      </ActionBox>
      <DataGrid rows={users.rows} columns={columns} />
    </RootListViewStyled>
  );
};
