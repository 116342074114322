import {
  GridColumnLookup,
  GridFilterItem,
  GridState,
} from "@mui/x-data-grid-pro";
import { stringify } from "query-string";

type SortingModel = GridState["sorting"]["sortModel"][0];

interface QueryParams {
  sort?: SortingModel["sort"];
  field?: SortingModel["field"];
  filter?: string;
  range?: number[];
  q?: string;
  columns: string;
  linkType?: string;
}

export const buildParams = (state: GridState) => {
  const types = {
    number: true,
    boolean: true,
    date: true,
    dateTime: true,
    string: true,
  };
  const { lookup } = state.columns;
  const columnTypes = Object.entries(lookup)
    .filter((col) => types[col[1].type || "string"] && col[1].filterable)
    .reduce((acc, cur) => {
      acc[cur[0]] = cur[1].type || "string";
      return acc;
    }, {} as Record<string, string>);
  const queryParams: QueryParams = { columns: JSON.stringify(columnTypes) };
  if (state.sorting?.sortModel?.length) {
    queryParams.sort = state.sorting.sortModel[0].sort;
    queryParams.field = state.sorting.sortModel[0].field;
  }
  if (state.filter.filterModel.items.length) {
    const _getFilters = getFilters.bind(null, lookup);
    const filters = state.filter.filterModel.items
      .filter(checkEligibleFilter)
      .map(_getFilters);
    if (filters.length) queryParams.filter = JSON.stringify(filters);
  }
  if (state.filter.filterModel.quickFilterValues?.length) {
    const q = state.filter.filterModel.quickFilterValues[0];
    const qColumns = getQuickSearchColumns(lookup, q);
    if (qColumns.length) {
      queryParams.q = q;
      queryParams.columns = JSON.stringify(qColumns);
    }
  }
  if (state.filter.filterModel.linkOperator) {
    queryParams.linkType = state.filter.filterModel.linkOperator;
  }
  queryParams.range = [state.pagination.page, state.pagination.pageSize];
  return stringify(queryParams);
};

function checkEligibleFilter({
  operatorValue: operator,
  value,
}: GridFilterItem) {
  if (!operator) return false;
  const numberOps: Record<string, true> = {
    "=": true,
    "!=": true,
    ">": true,
    ">=": true,
    "<": true,
    "<=": true,
  };
  if (operator === "isAnyOf") return Array.isArray(value) && value.length;
  if (numberOps[operator]) return !isNaN(Number(value));
  if (operator === "isEmpty" || operator === "isNotEmpty") return true;
  return value;
}

const operators: any = {
  equals: "eq",
  contains: "substring",
  startsWith: "startsWith",
  endsWith: "endsWith",
  isAnyOf: "in",
  "=": "eq",
  "!=": "ne",
  ">": "gt",
  ">=": "gte",
  "<": "lt",
  "<=": "lte",
  after: "gt",
  onOrAfter: "gte",
  before: "lt",
  onOrBefore: "lte",
};

const operatorTypes: any = {
  isEmpty: (type: string) => {
    if (type === "number") return { operatorValue: "or", value: [null, 0] };
    if (type === "string") return { operatorValue: "or", value: [null, ""] };
    else return { operatorValue: "is", value: null };
  },
  isNotEmpty: (type: string) => {
    if (type === "number") return { operatorValue: "gt", value: 0 };
    if (type === "string") return { operatorValue: "gt", value: "" };
    else return { operatorValue: "not", value: null };
  },
};

const getDateIsOrNot = (isOrNot: "is" | "not", type: string, value: any) => {
  const time = new Date(value);
  if (type === "dateTime")
    return {
      operatorValue: isOrNot === "is" ? "eq" : "ne",
      value: time.toISOString(),
    };
  const nextDate = new Date(time);
  nextDate.setDate(time.getDate() + 1);
  return {
    operatorValue: isOrNot === "is" ? "between" : "notBetween",
    value: [time.toISOString(), nextDate.toISOString()],
  };
};

const operatorTypesValues: any = {
  is: (type: string, value: any) => {
    if (type === "singleSelect") return { operatorValue: "eq", value };
    if (type === "boolean")
      return { operatorValue: value === "true" ? "is" : "not", value: true };
    return getDateIsOrNot("is", type, value);
  },
  not: (type: string, value: any) => {
    if (type === "singleSelect") return { operatorValue: "ne", value };
    return getDateIsOrNot("not", type, value);
  },
};

function getFilters(
  columns: GridColumnLookup,
  { columnField: field, operatorValue: operator, value }: GridFilterItem,
) {
  if (operatorTypesValues[operator!]) {
    return {
      ...operatorTypesValues[operator!](columns[field].type || "string", value),
      columnField: field,
    };
  }
  if (operatorTypes[operator!]) {
    return {
      ...operatorTypes[operator!](columns[field].type || "string", value),
      columnField: field,
    };
  }
  return {
    columnField: field,
    operatorValue: operators[operator!],
    value:
      columns[field].type === "date" || columns[field].type === "dateTime"
        ? new Date(value).toISOString()
        : value,
  };
}

function getQuickSearchColumns(columns: GridColumnLookup, q: string) {
  const isValidDate = !isNaN(new Date(q).valueOf());
  const isNumber = !isNaN(Number(q));
  return Object.entries(columns)
    .filter(([_col, info]) => info.filterable)
    .map(([col, info]) => {
      const { type } = info;
      if (type !== "singleSelect") return { col, type };
      const { valueOptions } = info;
      if (!valueOptions || typeof valueOptions === "function")
        return { col, type };
      const value =
        typeof valueOptions[0] === "object"
          ? valueOptions[0].value
          : valueOptions[0];
      return { col, type: typeof value };
    })
    .filter(
      ({ type }) =>
        type === "string" ||
        (type === "number" && isNumber) ||
        (isValidDate && (type === "date" || type === "dateTime")),
    );
}
