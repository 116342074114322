import { AppThunk } from "..";
import { main } from "./state";
import { authClient, uiActions } from "../states";

const { actions } = main;

export const mainActions = {
  cancelSession(id: string | number): AppThunk {
    return async (dispatch) => {
      dispatch(uiActions.setLoading(true));
      const { status, data } = await authClient.put(
        `/sessions/${id}/cancel`,
        {},
      );
      if (status === 200 && data.message) {
        dispatch(actions.setStatusMessage(data.message));
      } else {
        dispatch(uiActions.showError("error cancelling session"));
      }
      dispatch(uiActions.setLoading(false));
    };
  },
  confirmSession(id: string | number): AppThunk {
    return async (dispatch) => {
      dispatch(uiActions.setLoading(true));
      const { status, data } = await authClient.put(
        `/sessions/${id}/confirm`,
        {},
      );
      if (status === 200 && data.message) {
        dispatch(actions.setStatusMessage(data.message));
      } else {
        dispatch(uiActions.showError("error confirming session"));
      }
      dispatch(uiActions.setLoading(false));
    };
  },
  /**SESSIONS END */
};
