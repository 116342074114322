import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const initialState: UIState = {
  loading: false,
  notification: undefined, //{message: "hello", variant: "success"},
  menuOpen: true,
  view: {},
};

export const ui = {
  ...createSlice({
    name: "ui",
    initialState,
    reducers: {
      hideNotification(state) {
        state.notification = undefined;
      },
      setLoading(state, { payload }: PayloadAction<boolean>) {
        state.loading = payload;
      },
      setView(state, { payload }: PayloadAction<Partial<UIViewState>>) {
        state.view = {
          ...state.view,
          ...payload,
        };
      },
      showNotification(state, { payload }: PayloadAction<UINotification>) {
        if (!payload.duration) {
          payload.duration = 4000;
        }
        state.notification = payload;
      },
      setMenuOpen(state, { payload }: PayloadAction<boolean>) {
        state.menuOpen = payload;
      },
    },
  }),
  persist: false,
};

export interface UINotification {
  message: string;
  variant?: "error" | "warning" | "info" | "success";
  duration?: number;
}

export interface UIState {
  loading: boolean;
  notification?: UINotification;
  view: UIViewState;
  menuOpen: boolean;
}

export type UIViewType = boolean | string | Record<string, any> | undefined;

export interface UIViewState {
  // vehicleSpecsDialog?: UIViewType;
}
