import { useState } from "react";
import { Typography, Box } from "@mui/material";
import { styled } from "@mui/system";
import { SendMessageBar } from "../../components/buttons/SendMessageBar";
import { ChatResults } from "../../components/searchResults";

const StyledBox = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  height: "90vh",
  padding: theme.spacing(3),
  justifyContent: "space-between",
  boxSizing: "border-box",
}));

const Chatbot = () => {
  const [search, setSearch] = useState("");

  return (
    <StyledBox>
      <Typography variant="h4" align="center" color="primary">
        Admire Chatbot
      </Typography>
      <ChatResults search={search} sx={{ width: "75%" }}></ChatResults>
      <SendMessageBar
        sendMessage={(input) => {
          setSearch(input);
        }}
        disabled={false}
      />
    </StyledBox>
  );
};

export default Chatbot;
