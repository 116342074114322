import { Box, Autocomplete, TextField } from "@mui/material";
import { useState } from "react";

interface DropDownOption {
  id: string | number;
  name: string;
}

interface DropDownProps<DD extends DropDownOption> {
  options: DD[];
  value: DD | undefined;
  onValueChange: (value: DD | undefined) => void;
  sx?: any;
  label?: string;
}

export const DropDown = <DD extends DropDownOption>({
  options,
  value,
  sx,
  label,
  onValueChange,
}: DropDownProps<DD>) => {
  const [inputValue, setInputValue] = useState("");
  return (
    <Autocomplete
      options={options}
      getOptionLabel={(option) => option.name}
      renderInput={(params) => <TextField {...params} label={label} />}
      renderOption={(props, option) => (
        <Box component="li" {...props} key={option.id}>
          {option.name}
        </Box>
      )}
      value={value}
      inputValue={inputValue}
      onChange={(event, newValue) => {
        onValueChange(newValue as DD | undefined);
      }}
      onInputChange={(event, newInputValue) => {
        setInputValue(newInputValue);
      }}
      isOptionEqualToValue={(option, value) => option?.id === value?.id}
      sx={{ width: 450, ...sx }}
    />
  );
};
