import { Button, Grid } from "@mui/material";
import { Formik, Form } from "formik";
import { InputField } from "../inputs/InputField";
import { yup } from "../../lib";
import { adminActions, uiActions, useDispatch } from "../../state";
import { PaperDropzone } from "../";
import { UploadType } from "../../pages/admin/MediaCreatePage";
export interface UploadFormProps {
  uploadType: UploadType;
  closeModal: () => void;
}
export const UploadForm = ({ uploadType, closeModal }: UploadFormProps) => {
  const isSingle = uploadType === "single";
  const singleInitialValues = {
    label: "",
    name: "",
    file: "",
  };

  const bulkInitialValues = { file: "" };

  const validationSchema = yup.object({
    label: yup.string().when([], {
      is: () => isSingle,
      then: yup.string().required("Label is required"),
      otherwise: yup.string().optional().nullable(),
    }),
    name: yup.string().when([], {
      is: () => isSingle,
      then: yup.string().required("Name is required"),
      otherwise: yup.string().optional().nullable(),
    }),
    file: yup.mixed().required("file is required."),
  });

  const dispatch = useDispatch();

  const handleSubmit = async (values) => {
    if (
      (isSingle && values.file.length === 1) ||
      (!isSingle && values.file[0].path.includes(".zip"))
    ) {
      await dispatch(adminActions.saveMedia(values, !isSingle));
      closeModal();
    } else {
      dispatch(
        uiActions.showError("Make sure the correct upload type is selected"),
      );
    }
  };

  return (
    <Formik
      enableReinitialize
      initialValues={isSingle ? singleInitialValues : bulkInitialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      {({ isSubmitting, setFieldValue }) => (
        <Form>
          <Grid alignItems={"center"} container spacing={4}>
            <Grid
              item
              xs={12}
              md={12}
              gap={2}
              display={"flex"}
              flexDirection={"column"}
            >
              {isSingle && (
                <>
                  <InputField label="Label" name="label" />
                  <InputField label="Name" name="name" />
                </>
              )}
              <PaperDropzone
                filesCB={(files) => setFieldValue("file", files)}
                fileType={!isSingle ? ".zip" : "any"}
              />
            </Grid>
            <Grid item xs={12} md={12}>
              <Button
                fullWidth
                color="primary"
                variant="contained"
                type="submit"
                disabled={isSubmitting}
              >
                Save
              </Button>
            </Grid>
          </Grid>
        </Form>
      )}
    </Formik>
  );
};
