import { Button, Stack, TextField, Typography } from "@mui/material";
import Box from "@mui/material/Box";
import generate from "../../assets/img/generate.png";
import generate_key from "../../assets/img/generate_key.png";
import copy_key from "../../assets/img/copy_key.png";
import { useState } from "react";
import { adminActions, useDispatch } from "../../state";

const ImageList = () => {
  const dispatch = useDispatch();
  const [input, setInput] = useState("");
  const [submitted, setSubmitted] = useState(false);
  const handleSubmit = () => {
    dispatch(
      adminActions.uploadPaymoApiKey(input, () => {
        setSubmitted(true);
      }),
    );
    setInput("");
  };
  const images = [
    {
      src: generate_key,
      alt: "Generate Key",
      instruction: "Step 1: Click 'Generate New Key'",
    },
    {
      src: generate,
      alt: "Generate",
      instruction: "Step 2: Enter any name then click 'Generate'",
    },
    {
      src: copy_key,
      alt: "Copy Key",
      instruction: "Step 3: Copy the generated key",
    },
  ];
  return (
    <Box
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Button
        variant="contained"
        color="primary"
        sx={{ marginBottom: 2, top: -20 }}
        onClick={() => {
          window.open(
            "https://app.paymoapp.com/#Paymo.module.myaccount/api-keys",
          );
        }}
      >
        Click on this link to create your API key
      </Button>
      <Box style={{ margin: "5px", width: "30vw", height: "30vh" }}>
        <Typography variant="h6" align="center" marginBottom={1}>
          {images[0].instruction}
        </Typography>
        <img
          src={images[0].src}
          alt={images[0].alt}
          style={{ objectFit: "contain", width: "100%", height: "80%" }}
        />
      </Box>
      <Box
        style={{
          display: "flex",
          flexDirection: "row",
          width: "60vw",
          height: "47vh",
        }}
      >
        <Box style={{ margin: "5px", width: "50%" }}>
          <Typography variant="h6" align="center" marginBottom={1}>
            {images[1].instruction}
          </Typography>
          <img
            src={images[1].src}
            alt={images[1].alt}
            style={{ objectFit: "contain", width: "100%", height: "80%" }}
          />
        </Box>
        <Box style={{ margin: "5px", width: "50%" }}>
          <Typography variant="h6" align="center" marginBottom={1}>
            {images[2].instruction}
          </Typography>
          <img
            src={images[2].src}
            alt={images[2].alt}
            style={{ objectFit: "contain", width: "100%", height: "80%" }}
          />
        </Box>
      </Box>
      <Box sx={{ width: "100%" }}>
        {!submitted ? (
          <form style={{ width: "100%" }}>
            <Stack
              direction="row"
              justifyContent="center"
              alignItems="center"
              spacing={1}
            >
              <TextField
                value={input}
                onChange={(event) => setInput(event.target.value)}
                variant="outlined"
                label="Paste the generated api key here"
                fullWidth
                sx={{ maxWidth: "40%" }}
              />
              <Button
                type="submit"
                onClick={() => handleSubmit()}
                variant="contained"
                color="primary"
                disabled={!input}
              >
                Submit
              </Button>
            </Stack>
          </form>
        ) : (
          <Typography align="center">Thank you for submitting!</Typography>
        )}
      </Box>
    </Box>
  );
};

export function PaymoApiKeyUploadPage() {
  return (
    <Box sx={{ width: "100%" }}>
      <ImageList />
    </Box>
  );
}
