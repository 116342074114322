import { Modal as MUIModal, Box } from "@mui/material";
import { ReactNode } from "react";
import { useMobile, useTablet } from "../../themes";

interface ModalProps {
  open: boolean;
  onClose: () => void;
  children: ReactNode;
  sx?: any;
}

export default function Modal({ open, onClose, children, sx }: ModalProps) {
  const isTablet = useTablet();
  const isMobile = useMobile();
  const isSmallerThanPc = isTablet || isMobile;
  return (
    <MUIModal open={open} onClose={onClose}>
      <Box
        sx={{
          position: "relative",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: isSmallerThanPc ? "90%" : "40%",
          height: "fit-content",
          backgroundColor: "background.paper",
          border: "2px solid #000",
          boxShadow: 24,
          p: 4,
          ...sx,
        }}
      >
        {children}
      </Box>
    </MUIModal>
  );
}
