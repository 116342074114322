import {
  Grid,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from "@mui/material";
import { useState } from "react";
import { UploadForm } from "../../components";
export type UploadType = "single" | "bulk";
export const MediaCreatePage = ({ closeModal }) => {
  const [uploadType, setUploadType] = useState<UploadType>("single");
  return (
    <Grid
      container
      spacing={12}
      direction="row"
      justifyContent="center"
      paddingBottom={3}
    >
      <Grid item>
        <Typography variant="h3">
          Add Media
        </Typography>
        <ToggleButtonGroup
          fullWidth
          sx={{ mt: 4, mb: 4 }}
          value={uploadType}
          exclusive
          onChange={(e, v) => {
            if (!v) return;
            setUploadType(v);
          }}
        >
          <ToggleButton value={"single"}>
            <Typography>Single upload</Typography>
          </ToggleButton>
          <ToggleButton value={"bulk"}>
            <Typography>Batch upload</Typography>
          </ToggleButton>
        </ToggleButtonGroup>
        <UploadForm uploadType={uploadType} closeModal={closeModal} />
      </Grid>
    </Grid>
  );
};
