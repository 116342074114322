import {
  Box,
  Typography,
  IconButton,
  Dialog,
  TextField,
  Button,
  styled,
} from "@mui/material";
import ThumbUpIcon from "@mui/icons-material/ThumbUp";
import ThumbDownIcon from "@mui/icons-material/ThumbDown";
import { ReactNode, useState } from "react";

export const ChatContainer = styled(Box)(({ theme }) => ({
  width: "100%",
  height: "calc(100vh - 200px)",
  overflowY: "auto",
  padding: theme.spacing(2),
  borderRadius: 12,
  display: "flex",
  flexDirection: "column-reverse",
}));

const FeedbackContainer = styled(Box)(({ theme }) => ({
  position: "relative",
  display: "flex",
  justifyContent: "flex-end",
  marginBottom: theme.spacing(2),
  right: -45,
  bottom: -15,
}));

const SenderName = styled(Typography)(() => ({
  position: "absolute",
  fontSize: 12,
  top: -20,
  color: "grey",
  whiteSpace: "nowrap",
}));

const FeedbackButton = styled(IconButton)(() => ({
  position: "absolute",
  right: 0,
  bottom: 0,
  zIndex: 1,
}));

const FeedbackDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialog-paper": {
    width: 600,
    maxWidth: "90%",
    padding: theme.spacing(4),
  },
}));

const MessageWrapper = styled(Box)(({ theme }) => ({
  marginBottom: theme.spacing(0.8),
}));

const AMessage = styled(Typography)(({ theme }) => ({
  //maxWidth: "75%",
  padding: theme.spacing(1),
  borderRadius: 12,
  lineHeight: "1.4",
  whiteSpace: "pre-line",
  variant: "subtitle1",
  position: "relative",
  textAlign: "left",
}));

const MyMessage = styled(AMessage)(() => ({
  color: "#fff",
  backgroundColor: "#0b81ff",
  marginLeft: "auto",
}));

const TheirMessage = styled(AMessage)(() => ({
  color: "#000",
  backgroundColor: "#e0e0e0",
}));

interface MessageProps {
  me: boolean;
  name?: string;
  sx?: any;
  children: ReactNode;
  enableFeedback?: boolean;
  feedbackHandlers?: {
    handleThumbs: (thumb: "up" | "down") => void;
    handleFeedback: (feedback: string) => void;
  };
  prevMessage?: {
    me: boolean;
    name?: string;
  };
  maxWidth?: number | string;
}

export function ChatMessage({
  me,
  name,
  children,
  prevMessage,
  enableFeedback,
  feedbackHandlers,
  maxWidth = "75%",
}: MessageProps) {
  const [open, setOpen] = useState(false);
  const [feedbackInput, setFeedbackInput] = useState("");
  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false);
    setFeedbackInput("");
  };
  const notSameAsPrev = prevMessage?.name !== name;
  const makeSenderName = name && notSameAsPrev;
  const Message = me ? MyMessage : TheirMessage;
  return (
    <MessageWrapper
      style={{
        display: "flex",
        justifyContent: me ? "flex-end" : "flex-start",
      }}
    >
      <Message
        marginTop={!makeSenderName || me !== prevMessage?.me ? 0 : 1.5}
        maxWidth={maxWidth}
      >
        {children}
        {makeSenderName ? (
          <SenderName style={{ [me ? "right" : "left"]: 10 }}>
            {name}
          </SenderName>
        ) : (
          ""
        )}
      </Message>
      {enableFeedback && feedbackHandlers && (
        <FeedbackContainer>
          <FeedbackButton
            sx={{ color: "#b3b3b3" }}
            onClick={() => {
              handleOpen();
              feedbackHandlers.handleThumbs("up");
            }}
          >
            <ThumbUpIcon />
          </FeedbackButton>
          <FeedbackButton
            sx={{ color: "#b3b3b3", right: -30 }}
            onClick={() => {
              handleOpen();
              feedbackHandlers.handleThumbs("down");
            }}
          >
            <ThumbDownIcon />
          </FeedbackButton>
          <FeedbackDialog open={open} onClose={handleClose}>
            <Typography variant="h6" gutterBottom>
              Give feedback
            </Typography>
            <TextField
              autoFocus
              margin="dense"
              label="Your feedback"
              type="text"
              fullWidth
              multiline
              minRows={6}
              onChange={(event) => {
                setFeedbackInput(event.target.value);
              }}
              value={feedbackInput}
            />
            <Box mt={2}>
              <Button onClick={handleClose} color="primary">
                Cancel
              </Button>
              <Button
                disabled={!feedbackInput}
                onClick={() => {
                  handleClose();
                  feedbackHandlers.handleFeedback(feedbackInput);
                }}
                color="primary"
              >
                Submit
              </Button>
            </Box>
          </FeedbackDialog>
        </FeedbackContainer>
      )}
    </MessageWrapper>
  );
}
