import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { adminActions, adminSelectors } from "../../state";
import { Button } from "@mui/material";
import { AddIcon } from "../../components";
import { ActionBox, RootListViewStyled } from "./GenericPage.styles";
import { SLACK_FORWARDINGS_COLUMNS } from "../../components/tables/columns";
import { DataGridPro } from "@mui/x-data-grid-pro";
import { ToolBar } from "../../components/tables/ToolBar";
import { SlackForwardForm } from "../../components/forms/SlackForwardForm";
import Modal from "../../components/modal/Modal";

export const SlackForwardingPage = () => {
  const dispatch = useDispatch();
  const slackForwards = useSelector(adminSelectors.slackForwards);
  const [pageSize, setPageSize] = useState(25);
  const [openModel, setOpenModel] = useState(false);

  useEffect(() => {
    dispatch(adminActions.getSlackForwards());
    dispatch(adminActions.getSlackUsers());
  }, [dispatch]);

  return (
    <RootListViewStyled>
      <ActionBox>
        <Button
          fullWidth
          variant="outlined"
          color="primary"
          onClick={() => {
            setOpenModel(true);
          }}
        >
          <AddIcon /> Create Forward
        </Button>
      </ActionBox>
      <DataGridPro
        columns={SLACK_FORWARDINGS_COLUMNS(dispatch)}
        autoHeight
        rows={slackForwards.rows}
        disableSelectionOnClick
        rowsPerPageOptions={[10, 25, 50, 100, 250, 500, 1000]}
        pageSize={pageSize}
        onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
        pagination
        components={{
          Toolbar: ToolBar,
        }}
      />
      <Modal
        open={openModel}
        onClose={() => {
          setOpenModel(!openModel);
        }}
        sx={{ width: "75%", height: "75%" }}
      >
        <SlackForwardForm
          closeModal={() => {
            setOpenModel(!openModel);
          }}
        />
      </Modal>
    </RootListViewStyled>
  );
};
