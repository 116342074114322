import { useCallback, useEffect } from "react";
import { REACT_APP_GOOGLE_CLIENT_ID } from "../../config";
import { authActions, useAction } from "../../state";

export function OneTap() {
  const login = useAction(authActions.socialLogin);

  const handleCredentialResponse = useCallback(
    async function (response) {
      await login({ credential: response.credential, sign_in_type: "google" });
    },
    [login],
  );
  useEffect(() => {
    /** See here https://stackoverflow.com/a/69060489/12892971 */
    window.handleCredentialResponse = handleCredentialResponse;
  }, [handleCredentialResponse]);
  /**
   * See
   * https://developers.google.com/identity/gsi/web/reference/html-reference#element_with_id_g_id_onload
   * for all possible options
   * */
  return (
    <div
      id="g_id_onload"
      data-client_id={REACT_APP_GOOGLE_CLIENT_ID}
      data-callback="handleCredentialResponse"
      data-sign_in_type="google"
    ></div>
  );
}
