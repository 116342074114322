import { useEffect, useState } from "react";
import {
  Typography,
  Box,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Stack,
  SxProps,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { styled } from "@mui/system";
import { useSelector } from "react-redux";
import {
  adminActions,
  adminSelectors,
  authClient,
  uiActions,
} from "../../state";
import { useDispatch } from "react-redux";
import { ChatMessage } from "../../components/text/ChatMessages";
import { Rating } from "react-simple-star-rating";
import { StarRatings } from "../../components/text/StarRatings";
import expert from "../../assets/img/expert.svg";
import { useMobile } from "../../themes";

export const ChatContainer = styled(Box)(({ theme }) => ({
  width: "100%",
  padding: theme.spacing(2),
  borderRadius: 12,
}));

export const Results = styled(Box)(() => ({
  width: "100%",
  height: "calc(100vh - 320px)",
  overflowY: "auto",
}));

const TicketTitle = ({
  desc = "",
  org = "",
  index,
  isExpert = false,
  rating = 0,
  totalRatings = 0,
}) => {
  const isMobile = useMobile();
  const descLen = (desc || "").length;
  const orgLen = org.length;
  const cutDesc = 100 - orgLen;
  return (
    <Stack
      direction={isMobile ? "column" : "row"}
      justifyContent="space-between"
      alignItems="center"
      width="100%"
      marginRight={isMobile ? 0 : 2}
      gap={isMobile ? 1 : 0}
      fontSize={isMobile ? 12 : 14}
    >
      {!isMobile && (
        <Typography width="16%" align="left">
          {index + 1}.
        </Typography>
      )}
      <Stack
        direction="row"
        width={isMobile ? "100%" : "60%"}
        justifyContent="space-between"
      >
        <Typography color="text.primary" align="left">
          {desc?.substring(0, cutDesc) + (descLen >= cutDesc ? "..." : "")}
        </Typography>
        <Typography color="text.secondary" align="right">
          {org}
        </Typography>
      </Stack>
      <Stack
        direction="row"
        alignItems="center"
        width="17.3%"
        justifyContent={isExpert ? "space-between" : "flex-end"}
      >
        {isExpert && <img src={expert} alt="expert" />}
        <StarRatings
          rating={rating}
          allowFraction
          totalRatings={totalRatings}
          type="mini"
        />
      </Stack>
    </Stack>
  );
};

interface TicketsResultsProps {
  search: string;
  hide?: boolean;
  limit?: number;
  sx?: SxProps;
}

const SearchTicketsPage = ({
  search,
  hide,
  limit = 100,
  sx,
}: TicketsResultsProps) => {
  const dispatch = useDispatch();
  const [rated, setRated] = useState<Record<string, number>>({});
  const results = useSelector(adminSelectors.milvusRes);

  useEffect(() => {
    if (search) {
      setRated({});
      dispatch(adminActions.getSearchMilvusRes(search, limit));
    }
  }, [search]);

  return (
    <Box sx={sx}>
      {!hide &&
        results.rows.map(({ ticket }, index) => {
          const { ratings, notes, id, description, required_company, contact } =
            ticket;
          const totalRatings = ratings?.length || 0;
          const rating = ratings?.length
            ? ratings.reduce((acc, { rating }) => acc + rating, 0) /
              totalRatings
            : 0;
          const stats = { 5: 0, 4: 0, 3: 0, 2: 0, 1: 0 };
          ratings?.reduce((acc, { rating }) => {
            if (!acc[rating]) acc[rating] = 0;
            acc[rating]++;
            return acc;
          }, stats);
          return (
            <Accordion key={index}>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <TicketTitle
                  index={index}
                  desc={description}
                  org={required_company}
                  isExpert={!!notes.find((n) => n.reliability)}
                  rating={rating}
                  totalRatings={totalRatings}
                />
              </AccordionSummary>
              <AccordionDetails>
                <Box display="flex" justifyContent="flex-end">
                  {rated[id] ? (
                    <Typography color="grey" marginRight={2}>
                      {"Thank You For Rating! (" + rated[id] + " stars)"}
                    </Typography>
                  ) : (
                    <Box
                      display="flex"
                      flexDirection="column"
                      alignItems="center"
                    >
                      <Typography color="grey" variant="caption">
                        How useful is this ticket?
                      </Typography>
                      <Rating
                        onClick={async (value) => {
                          const { status } = await authClient.post(
                            "tickets/search_rating",
                            { ticket_id: id, rating: value },
                          );
                          if (status === 204) {
                            setRated({ ...rated, [id]: value });
                          } else {
                            dispatch(uiActions.showError("rating failed"));
                          }
                        }}
                      />
                    </Box>
                  )}
                </Box>
                <ChatContainer>
                  <ChatMessage me={false} name={contact}>
                    {description}
                  </ChatMessage>
                  {notes.map(
                    (
                      {
                        summary_notes,
                        internal_notes,
                        created_by_contact,
                        created_by_resource,
                      },
                      idx,
                      allNotes,
                    ) => (
                      <ChatMessage
                        key={idx}
                        me={!!created_by_resource}
                        name={created_by_contact || created_by_resource}
                        prevMessage={
                          allNotes[idx - 1]
                            ? {
                                me: !!allNotes[idx - 1].created_by_resource,
                                name:
                                  allNotes[idx - 1].created_by_contact ||
                                  allNotes[idx - 1].created_by_resource,
                              }
                            : undefined
                        }
                      >
                        {summary_notes || internal_notes || ""}
                      </ChatMessage>
                    ),
                  )}
                </ChatContainer>
                {ratings?.length ? (
                  <Box display="flex" justifyContent="flex-end">
                    <StarRatings
                      rating={rating}
                      type="full"
                      stats={stats}
                      totalRatings={totalRatings}
                      allowFraction
                    />
                  </Box>
                ) : (
                  <></>
                )}
              </AccordionDetails>
            </Accordion>
          );
        })}
    </Box>
  );
};

export default SearchTicketsPage;
