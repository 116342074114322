import { useEffect, useState } from "react";
import { useDispatch, useSelector, AppThunk, AppState } from "../../state";
import { Typography } from "@mui/material";
import { ServerDataGrid } from "../../components/tables/ServerDataGrid";
import { GridColDef, GridValidRowModel } from "@mui/x-data-grid";
import { RootListViewStyled } from "../../pages/admin/GenericPage.styles";

interface GenericSSPProps<L extends string> {
  rowsSelector: (state: AppState) => { count: number; rows: any[] };
  adminAction: (tableStateParams: string) => AppThunk;
  columns: GridColDef<any, any, any>[];
  dataDisplayName: string;
  checkboxActions?: {
    labels: L[];
    onClick: (selectedAction: L, selectedRows: GridValidRowModel[]) => void;
  };
}

export default function GenericSSPPage<L extends string>({
  rowsSelector,
  adminAction,
  columns,
  dataDisplayName,
  checkboxActions,
}: GenericSSPProps<L>) {
  const dispatch = useDispatch();
  const [tableStateParams, setTableStateParams] = useState("");
  const { rows, count } = useSelector(rowsSelector);

  useEffect(() => {
    dispatch(adminAction(tableStateParams));
  }, [dispatch]);

  useEffect(() => {
    if (tableStateParams) {
      dispatch(adminAction(tableStateParams));
    }
  }, [dispatch, tableStateParams]);

  return (
    <RootListViewStyled>
      <Typography variant="h3" mb={3} color="primary">
        {dataDisplayName}
      </Typography>
      <ServerDataGrid
        rows={rows}
        rowCount={count}
        columns={columns}
        setTableStateParams={setTableStateParams}
        checkboxActions={checkboxActions}
        toolbarProps={{
          showSearchBar: true,
          placeholder: "Search for " + dataDisplayName,
        }}
      />
    </RootListViewStyled>
  );
}
