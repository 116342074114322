import { useEffect, memo } from "react";
import Notification from "../../components/Notification";
import { Outlet } from "react-router-dom";
import {
  RootStyle,
  LeftPanel,
  RightPanel,
  OutletPanel,
} from "./AuthLayout.styles";
import { useSelector } from "react-redux";
import { authSelectors, uiSelectors } from "../../state";
import { Navigation } from "../../lib";
import { Backdrop, CircularProgress } from "@mui/material";
import logo from "../../assets/img/logo.svg";

export const AuthLayout = memo(function AuthLayout() {
  const isLoggedIn = useSelector(authSelectors.isLoggedIn);
  const requiresMfa = useSelector(authSelectors.requiresMfa);
  const loading = useSelector(uiSelectors.loading);
  useEffect(() => {
    if (isLoggedIn === true && requiresMfa === false) {
      Navigation.go("/admin");
    } else if (requiresMfa === true) {
      Navigation.go("/auth/multifactor-auth");
    }
  }, [isLoggedIn, requiresMfa]);
  return (
    <RootStyle>
      <LeftPanel>
        <img src={logo} style={{ height: 160 }} alt="logo" />
      </LeftPanel>
      <RightPanel>
        <OutletPanel>
          <Outlet />
        </OutletPanel>
      </RightPanel>
      <Notification />
      {loading && (
        <Backdrop open={true}>
          <CircularProgress size={128} />
        </Backdrop>
      )}
    </RootStyle>
  );
});
