import React, { useEffect } from "react";
import { DataGridPro } from "@mui/x-data-grid-pro";
import {
  useDispatch,
  adminActions,
  useSelector,
  adminSelectors,
} from "../../state";
import { Box, Typography } from "@mui/material";
import { RootListViewStyled } from "./GenericPage.styles";
import { ToolBar } from "../../components/tables/ToolBar";
import { PAYMO_USERS_COLUMNS } from "../../components/tables/columns";

export default function PaymoUsersPage() {
  const dispatch = useDispatch();
  const [pageSize, setPageSize] = React.useState(25);
  const { rows, count } = useSelector(adminSelectors.paymoUsers);

  useEffect(() => {
    dispatch(adminActions.getPaymoUsers());
  }, [dispatch]);

  return (
    <RootListViewStyled>
      <Typography variant="h3" mb={3} color="primary">
        Paymo Users
      </Typography>
      {count ? (
        <DataGridPro
          autoHeight
          rows={rows}
          columns={PAYMO_USERS_COLUMNS}
          disableSelectionOnClick
          rowsPerPageOptions={[10, 25, 50, 100, 250, 500, 1000, count]}
          pageSize={pageSize}
          onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
          pagination
          components={{
            Toolbar: ToolBar,
          }}
        />
      ) : (
        <Box display={"flex"} justifyContent={"center"}>
          <Typography fontSize={24} fontWeight={"bold"} color={"forestgreen"}>
            There are no Paymo Users
          </Typography>
        </Box>
      )}
    </RootListViewStyled>
  );
}
