import * as React from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import { SxProps } from "@mui/material";

interface TabPanelProps {
  component: (hide: boolean) => JSX.Element;
  index: number;
  value: number;
}

function CustomTabPanel(props: TabPanelProps) {
  const { component, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      <Box sx={{ p: 3 }}>{component(value !== index)}</Box>
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

interface BasicTabProps {
  panels: {
    label: string;
    component: (hide: boolean) => JSX.Element;
  }[];
  sx?: SxProps;
}

export default function BasicTabs({ panels, sx }: BasicTabProps) {
  const [value, setValue] = React.useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  return (
    <Box sx={{ width: "100%", ...sx }}>
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="basic tabs example"
          centered
          variant="fullWidth"
        >
          {panels.map(({ label }, i) => (
            <Tab label={label} {...a11yProps(i)}></Tab>
          ))}
        </Tabs>
      </Box>
      {panels.map(({ component }, i) => (
        <CustomTabPanel value={value} index={i} component={component} />
      ))}
    </Box>
  );
}
