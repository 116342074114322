import List from "@mui/material/List";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import { People, Dashboard, Summarize } from "@mui/icons-material";
import PermMediaIcon from "@mui/icons-material/PermMedia";
import AdminPages from "../../pages/admin";
import { useNavigate, useLocation } from "react-router-dom";
import { Box, ListItemButton } from "@mui/material";
import ProfileMenu from "./ProfileMenu";
import { useMobile } from "../../themes";
import {
  authSelectors,
  uiActions,
  useDispatch,
  useSelector,
} from "../../state";
import SearchIcon from "@mui/icons-material/Search";
import ChatBubbleIcon from "@mui/icons-material/ChatBubble";
import KeyIcon from "@mui/icons-material/Key";
import CircleNotificationsIcon from "@mui/icons-material/CircleNotifications";
import TabletAndroidIcon from "@mui/icons-material/TabletAndroid";
import NfcIcon from "@mui/icons-material/Nfc";

const rolesGroups = {
  onlySuper: ["super_admin"],
  admins: ["super_admin", "admin"],
  employee: ["super_admin", "admin", "employee"],
  paymoAdmins: ["super_admin", "admin", "employee", "paymo_admin"],
  paymoEmployees: [
    "super_admin",
    "admin",
    "employee",
    "paymo_admin",
    "paymo_employee",
  ],
  uuidPoster: ["super_admin", "uuid_poster"],
};

const menuItems = [
  {
    label: "Dashboard",
    Icon: Dashboard,
    to: AdminPages.dashboard.path,
    roles: rolesGroups.paymoEmployees,
  },
  {
    label: "Users",
    Icon: People,
    to: AdminPages.users.path,
    roles: rolesGroups.onlySuper,
  },
  {
    label: "Media",
    Icon: PermMediaIcon,
    to: AdminPages.media.path,
    roles: rolesGroups.onlySuper,
  },
  {
    label: "Projects",
    Icon: Summarize,
    to: AdminPages.projects.path,
    roles: rolesGroups.admins,
  },
  {
    label: "Tasks",
    Icon: Summarize,
    to: AdminPages.tasks.path,
    roles: rolesGroups.admins,
  },
  {
    label: "Paymo Users",
    Icon: Summarize,
    to: AdminPages.paymoUsers.path,
    roles: rolesGroups.admins,
  },
  {
    label: "Clients",
    Icon: Summarize,
    to: AdminPages.clients.path,
    roles: rolesGroups.admins,
  },
  {
    label: "Calendly Events",
    Icon: Summarize,
    to: AdminPages.eventsPage.path,
    roles: rolesGroups.paymoEmployees,
  },
  {
    label: "Sessions",
    Icon: Summarize,
    to: AdminPages.sessionsDetails.path,
    roles: rolesGroups.admins,
  },
  {
    label: "Discussions",
    Icon: Summarize,
    to: AdminPages.discussions.path,
    roles: rolesGroups.admins,
  },
  {
    label: "Tickets",
    Icon: Summarize,
    to: AdminPages.tickets.path,
    roles: rolesGroups.admins,
  },
  {
    label: "Slack Forwarding",
    Icon: CircleNotificationsIcon,
    to: AdminPages.slackForwardingPage.path,
    roles: rolesGroups.admins,
  },
  {
    label: "Organizations",
    Icon: Summarize,
    to: AdminPages.orgsPage.path,
    roles: rolesGroups.uuidPoster,
  },
  {
    label: "Chatbot",
    Icon: ChatBubbleIcon,
    to: AdminPages.chatbot.path,
    roles: rolesGroups.paymoEmployees,
  },
  {
    label: "Search Tickets",
    Icon: SearchIcon,
    to: AdminPages.searchTickets.path,
    roles: rolesGroups.paymoEmployees,
  },
  {
    label: "Search Community",
    Icon: SearchIcon,
    to: AdminPages.searchCommunity.path,
    roles: rolesGroups.paymoEmployees,
  },
  {
    label: "Search All",
    Icon: SearchIcon,
    to: AdminPages.searchAllPage.path,
    roles: rolesGroups.paymoEmployees,
  },
  {
    label: "Upload API key",
    Icon: KeyIcon,
    to: AdminPages.paymoApiKeyUpload.path,
    roles: rolesGroups.paymoEmployees,
  },
  {
    label: "Devices",
    Icon: TabletAndroidIcon,
    to: AdminPages.devicesPage.path,
    roles: rolesGroups.uuidPoster,
  },
  {
    label: "NFC UUIDS",
    Icon: NfcIcon,
    to: AdminPages.nfcUuidsPage.path,
    roles: rolesGroups.uuidPoster,
  },
];

export default function Menu() {
  const navigate = useNavigate();
  const location = useLocation();
  const isMobile = useMobile();
  const dispatch = useDispatch();
  const roles = useSelector(authSelectors.roles);

  const handleDrawerClose = () => {
    dispatch(uiActions.setMenuOpen(false));
  };
  const itemButtonClick = (to) => {
    if (isMobile) {
      handleDrawerClose();
    }
    navigate(to);
  };
  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          height: "100%",
        }}
      >
        <Box>
          <List>
            {menuItems
              .filter(({ roles: pageRoles }) =>
                roles.find(({ internal_name }) =>
                  pageRoles.includes(internal_name),
                ),
              )
              .map(({ label, Icon, to }) => (
                <ListItemButton
                  onClick={() => itemButtonClick(to)}
                  key={label}
                  selected={location.pathname === to}
                >
                  <ListItemIcon>
                    <Icon />
                  </ListItemIcon>
                  <ListItemText primary={label} />
                </ListItemButton>
              ))}
          </List>
        </Box>
        <Box>
          <List>
            <ProfileMenu />
          </List>
        </Box>
      </Box>
    </>
  );
}
