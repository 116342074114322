import { adminActions, adminSelectors } from "../../state";
import { TICKETS_COLUMNS } from "../../components/tables/columns";
import GenericSSPPage from "../../components/tables/GenericServerSidePage";

export default function TicketsPage() {
  return (
    <GenericSSPPage
      adminAction={adminActions.getTickets}
      rowsSelector={adminSelectors.tickets}
      columns={TICKETS_COLUMNS}
      dataDisplayName="Tickets"
    />
  );
}
