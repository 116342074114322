import { useEffect, useState } from "react";
import { GridColDef, GridRenderCellParams } from "@mui/x-data-grid";
import { useDispatch, useSelector } from "react-redux";
import { adminActions, adminSelectors } from "../../state";
import { Box, Button } from "@mui/material";
import { AddIcon, ConfirmDeleteDialog } from "../../components";
import { ActionBox, RootListViewStyled } from "./GenericPage.styles";
import { useMobile } from "../../themes";
import { ServerDataGrid } from "../../components/tables/ServerDataGrid";
import { formatBytes } from "../../lib";
import { MediaCreatePage } from "./MediaCreatePage";
import Modal from "../../components/modal/Modal";

export const MediaPage = () => {
  const dispatch = useDispatch();
  const mediaFiles = useSelector(adminSelectors.media);
  const rowCount = useSelector(adminSelectors.mediaCount);
  const isMobile = useMobile();
  const columns: GridColDef[] = [
    { field: "id", headerName: "Id", width: 90 },
    // Can't filter by virtuals (i.e `full_url` field)
    {
      field: "label",
      headerName: "File Name",
      width: 300,
      renderCell: (p) => (
        <a href={p.row.full_url} target="_blank" rel="noreferrer">
          {p.row.label}
        </a>
      ),
    },
    {
      field: "path",
      headerName: "File Path",
      width: 600,
    },
    {
      field: "status",
      headerName: "Status",
      width: 80,
    },
    {
      field: "internal_name",
      headerName: "Internal Name",
      width: 300,
    },
    {
      field: "size",
      headerName: "Size",
      width: 90,
      valueGetter: (v) => formatBytes(v.row.size),
    },
    {
      field: "actions",
      headerName: "Actions",
      width: 150,
      align: "right",
      headerAlign: "right",
      renderCell: (params: GridRenderCellParams<number>) => (
        <ConfirmDeleteDialog
          handleConfirm={() => dispatch(adminActions.deleteMedia(params.id))}
        />
      ),
    },
  ];

  const [tableStateParams, setTableStateParams] = useState("");
  const [openModal, setOpenModal] = useState(false);
  const modalClick = () => {
    setOpenModal(!openModal);
  };
  useEffect(() => {
    if (tableStateParams && !openModal) {
      dispatch(adminActions.getMedia(tableStateParams));
    }
  }, [dispatch, tableStateParams, openModal]);

  useEffect(() => {
    if (!openModal) {
      dispatch(adminActions.getMedia(""));
    }
  }, [dispatch, openModal]);

  return (
    <RootListViewStyled>
      <ActionBox>
        <Box style={{ width: isMobile ? "100%" : undefined }}>
          <Button
            fullWidth
            variant="outlined"
            color="primary"
            onClick={modalClick}
          >
            <AddIcon /> Add Media
          </Button>
        </Box>
      </ActionBox>
      <ServerDataGrid
        rowCount={rowCount}
        rows={mediaFiles.rows}
        columns={columns}
        setTableStateParams={setTableStateParams}
        toolbarProps={{
          showSearchBar: true,
          placeholder: "Search for user",
        }}
      />
      <Modal open={openModal} onClose={modalClick}>
        <MediaCreatePage closeModal={modalClick} />
      </Modal>
    </RootListViewStyled>
  );
};
