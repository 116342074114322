import {
  AutocompleteChangeReason,
  Box,
  Chip,
  FormControl,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  Autocomplete as MUIAutocomplete,
  TextField as MUITextField,
} from "@mui/material";
import { format, isValid, parse } from "date-fns";
import { Field, FormikValues, useFormikContext } from "formik";
import { TextField } from "formik-mui";
import {
  DatePicker as MUIDatePicker,
  DateTimePicker as MUIDateTimePicker,
} from "@mui/x-date-pickers";
import { useEffect, useRef, useState } from "react";
import { useMobile } from "../../themes";

const MyTestField = (props) => {
  const fieldRef: any = useRef();

  return <TextField {...props} inputRef={fieldRef} />;
};
export const InputField = ({
  label,
  name,
  type = "text",
  disabled = false,
  helperText = "",
  checkForAutoComplete = false,
}) => {
  const { touched, errors, values, setFieldTouched } =
    useFormikContext<FormikValues>();
  const isMobile = useMobile();
  useEffect(() => {
    const inputs = window.document.querySelectorAll<HTMLInputElement>(
      ".MuiInputBase-input",
    );
    inputs.forEach((input: HTMLInputElement) => {
      let notAutoCompleted = true;
      if (input && checkForAutoComplete) {
        notAutoCompleted =
          window.getComputedStyle(input, ":-webkit-autofill")
            .backgroundColor === "rgba(0, 0, 0, 0)" ||
          window.getComputedStyle(input, ":-webkit-autofill")
            .backgroundColor === "rgb(0, 0, 0)" ||
          window.getComputedStyle(input, ":-webkit-autofill")
            .backgroundColor === "";
        if (!notAutoCompleted) {
          setFieldTouched(name, true, true);
          return;
        }
      }
    });
  }, [values[name]]);
  const showError = () => {
    return touched[name] && Boolean(errors[name]);
  };
  return (
    <Field
      component={MyTestField}
      fullWidth
      name={name}
      className={isMobile ? "mobile-root" : ".MuiTextField-root"}
      label={label}
      type={type}
      disabled={disabled}
      error={showError()}
      helperText={
        helperText === "" ? touched[name] && errors[name] : helperText
      }
    />
  );
};

interface SelectOption {
  id: number | string;
  label: string;
}

interface SelectFieldProps {
  label: string;
  name: string;
  options: SelectOption[];
  initialValues: FormikValues;
  sx?: Record<string, any>;
}

export const SelectField = ({
  label,
  name,
  options,
  initialValues,
  sx = {},
}: SelectFieldProps) => {
  const { setFieldValue, values } = useFormikContext<typeof initialValues>();
  const handleSelectChange = (event, setFieldValue) => {
    const {
      target: { value },
    } = event;
    setFieldValue([name], value);
  };

  const { touched, errors } = useFormikContext<FormikValues>();

  return (
    <MUITextField
      sx={sx}
      fullWidth
      select
      value={values[name]}
      onChange={(e) => handleSelectChange(e, setFieldValue)}
      label={label}
      error={touched[name] && Boolean(errors[name])}
      helperText={(touched[name] && errors[name]) as boolean}
    >
      {options.map((o) => (
        <MenuItem key={`select-${o.id}`} value={o.id}>
          {o.label}
        </MenuItem>
      ))}
    </MUITextField>
  );
};

export const MultiSelectField = ({
  label,
  name,
  options,
  sx = {},
}: SelectFieldProps) => {
  const { values, setFieldValue } = useFormikContext<FormikValues>();
  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };

  return (
    <FormControl sx={{ width: "100%", ...sx }}>
      <InputLabel>{label}</InputLabel>
      <Select
        labelId="select-multiple-chip-input"
        id="select-multiple-chip"
        multiple
        value={values[name] ?? []}
        onChange={(e) => setFieldValue(name, e.target.value)}
        input={<OutlinedInput label={label} id="select-multiple-chip-input" />}
        renderValue={(selected) => (
          <Box
            sx={{
              display: "flex",
              flexWrap: "wrap",
              gap: 0.5,
            }}
          >
            {selected.map((value: any) => (
              <Chip
                key={value}
                label={options.find((r: any) => r.id === value)?.label}
              />
            ))}
          </Box>
        )}
        MenuProps={MenuProps}
      >
        {options.map((i: any, idx) => (
          <MenuItem key={`${label}_${idx}`} value={i.id}>
            {i.label}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};
/**
 * @param label  //placeholder
 * @param  options //in the dropdowns
 * @param  optionLabel // what the dropdown options look like
 * @param  render // what inside the textfield looks like
 * @param  equal: // isOptionEqualToValue test
 * @param fieldName // sometimes mui doesn't register the change effect in formik
 * @param optionValue // most time options is an object this is to select which value
 */
interface AutocompleteProps {
  label: string;
  options: any[];
  optionLabel: (option) => string;
  render: (option) => string;
  equal: (option, value) => boolean;
  fieldName: string;
  optionValue?: string;
}

export const Autocomplete = ({
  label,
  options,
  optionLabel,
  render,
  equal,
  fieldName,
  optionValue,
}: AutocompleteProps) => {
  const { setFieldValue, errors, touched, values, setFieldTouched, setErrors } =
    useFormikContext<FormikValues>();
  const [inputValue, setInputValue] = useState(values[fieldName] ?? "");
  useEffect(() => {
    setInputValue(values[fieldName]);
  }, [values[fieldName]]);
  const isMobile = useMobile();
  const handleSelectChange = (value) => {
    setFieldTouched(fieldName, true);
    if (value.trim() === "") {
      setFieldValue(fieldName, "");
      setInputValue("");
    } else if (
      fieldName &&
      optionValue &&
      options.find((o) => o[optionValue] === value)
    ) {
      setFieldValue(fieldName, value);
      setInputValue(value);
    } else {
      // check if auto fill in options
      const foundOption = options.reduce((a, b) => {
        if (
          JSON.stringify(b).toUpperCase().includes(value.toUpperCase()) &&
          value.trim() !== ""
        ) {
          a.push(b);
        }
        return a;
      }, []);
      if (foundOption && optionValue && value.trim() !== "") {
        // since its checking on includes its possible to get more than one
        if (foundOption.length === 1) {
          const val = foundOption[0][optionValue]?.toString() ?? value;
          setFieldValue(fieldName, val);
        }
        // if more than one option is available for search term and continue clicked will show error
        // i.e in state drop down if customer clicks continue after just typing `new`
        // an error will show since it can be New York , New Jersey ...
        else {
          setErrors({ ...errors, [fieldName]: errors[fieldName] });
          setFieldValue(fieldName, "");
          setInputValue("");
        }
      }
      setInputValue(value ?? "");
    }
  };

  const showError = () => {
    return (
      touched[`${fieldName}`] &&
      Boolean(errors[`${fieldName}`]) &&
      errors[`${fieldName}`]
    );
  };
  return (
    <MUIAutocomplete
      options={options}
      getOptionLabel={(o) =>
        optionLabel(o) ??
        optionLabel(
          options.find(
            (op) =>
              optionValue &&
              op[optionValue] &&
              op[optionValue].toString() === o.toString(),
          ),
        ) ??
        o
      }
      value={values[fieldName]}
      style={{ width: "100%" }}
      onChange={(
        event: any,
        newValue: any,
        reason: AutocompleteChangeReason,
      ) => {
        if (reason === "clear") {
          handleSelectChange("");
          setFieldTouched(fieldName, true);
        } else if (reason === "selectOption") {
          handleSelectChange(
            (optionValue && newValue[optionValue]) || newValue,
          );
        }
      }}
      inputValue={inputValue}
      renderInput={(params) => (
        <MUITextField
          className={
            isMobile
              ? "mobile-root autocomplete"
              : ".MuiTextField-root autocomplete"
          }
          error={Boolean(showError())}
          helperText={showError() as boolean}
          {...params}
          inputProps={{
            ...params.inputProps,
            // onBlur: (e) => {
            //   e.preventDefault();
            //   const { value } = e.target as HTMLInputElement;
            //   if (value !== "" && !/\p{Emoji}/u.test(value)) {
            //     handleSelectChange(value);
            //   }
            // },
            onChange: (e) => {
              e.preventDefault();
              const { value } = e.target as HTMLInputElement;

              handleSelectChange(value);
            },
          }}
          label={label}
        />
      )}
      renderOption={(props, option) => (
        <Box component="li" {...props}>
          {render(option)}
        </Box>
      )}
      isOptionEqualToValue={(option, value) => equal(option, value)}
    />
  );
};

export const DateTimePicker = ({
  label,
  fieldName,
  delimiter = "20",
  values,
  setFieldValue,
  errors,
  touched,
}) => {
  const showError = () => {
    return (
      touched[`${fieldName}`] &&
      Boolean(errors[`${fieldName}`]) &&
      errors[`${fieldName}`]
    );
  };
  return (
    <Field
      component={MUIDateTimePicker}
      disablePast
      label={label}
      name={fieldName}
      value={parse(values[fieldName], "yyyy-MM-dd hh:mm a", new Date())}
      onChange={(v: Date | undefined, keyboardV: string | undefined) => {
        if ((!v && !keyboardV) || (!isValid(v) && !isValid(keyboardV))) {
          return;
        }
        if (keyboardV || v) {
          // picker get first dibs
          const whichDate = v
            ? new Date(v)
            : keyboardV
            ? new Date(keyboardV)
            : new Date();
          // See https://stackoverflow.com/a/52352512
          const date = format(whichDate, "yyyy-MM-dd hh:mm a");
          // formatting date would result in 00 prefix if only 2 digits were inserted
          setFieldValue(fieldName, date.replace(/^00/, delimiter));
        }
      }}
      renderInput={(params) => {
        return (
          <MUITextField
            {...params}
            onChange={(e) => {
              const { value: v } = e.target as HTMLInputElement;
              if (!v) {
                return;
              }
              if (isValid(v)) {
                const parseDate = parse(v, "yyyy-MM-dd hh:mm a", new Date());
                const whichDate = parseDate ? new Date(parseDate) : new Date();
                // formatting child dob would result in 00 prefix if only 2 digits replace
                const date = format(whichDate, "yyyy-MM-dd hh:mm a").replace(
                  /^00/,
                  delimiter,
                );
                setFieldValue(fieldName, date);
              }
            }}
            error={Boolean(showError())}
            helperText={showError()}
          />
        );
      }}
    />
  );
};

export const DatePicker = ({
  label,
  fieldName,
  delimiter = "20",
  values,
  setFieldValue,
  errors,
  touched,
}) => {
  const showError = () => {
    return (
      touched[`${fieldName}`] &&
      Boolean(errors[`${fieldName}`]) &&
      errors[`${fieldName}`]
    );
  };
  return (
    <Field
      component={MUIDatePicker}
      label={label}
      name={fieldName}
      value={parse(values[fieldName], "yyyy-MM-dd", new Date())}
      onChange={(v: Date | undefined, keyboardV: string | undefined) => {
        if ((!v && !keyboardV) || (!isValid(v) && !isValid(keyboardV))) {
          return;
        }
        if (keyboardV || v) {
          // picker get first dibs
          const whichDate = v
            ? new Date(v)
            : keyboardV
            ? new Date(keyboardV)
            : new Date();
          // See https://stackoverflow.com/a/52352512
          const dtDateOnly = new Date(
            whichDate.valueOf() + whichDate.getTimezoneOffset() * 60 * 1000,
          );
          const date = format(dtDateOnly, "yyyy-MM-dd");
          // formatting date would result in 00 prefix if only 2 digits were inserted
          setFieldValue(fieldName, date.replace(/^00/, delimiter));
        }
      }}
      renderInput={(params) => {
        return (
          <MUITextField
            {...params}
            onChange={(e) => {
              const { value: v } = e.target as HTMLInputElement;
              if (!v) {
                return;
              }
              if (isValid(v)) {
                const parseDate = parse(v, "yyyy-MM-dd", new Date());
                const whichDate = parseDate ? new Date(parseDate) : new Date();
                // formatting child dob would result in 00 prefix if only 2 digits replace
                const date = format(whichDate, "yyyy-MM-dd").replace(
                  /^00/,
                  delimiter,
                );
                setFieldValue(fieldName, date);
              }
            }}
            error={Boolean(showError())}
            helperText={showError()}
          />
        );
      }}
    />
  );
};
