import { debounce, Grid, TextField, Stack } from "@mui/material";
import { GridToolbarContainer, GridToolbarExport } from "@mui/x-data-grid-pro";
import { ChangeEvent } from "react";
import { MenuPopupButton } from "../buttons/MenuPopupButton";

interface Props<L extends string> {
  showSearchBar: boolean;
  handleQuickSearch?: (_event: ChangeEvent<HTMLInputElement>) => void;
  placeholder?: string;
  checkboxActions?: { labels: L[]; onClick: (selectedAction: L) => void };
}

export const ToolBar = <L extends string>({
  showSearchBar = false,
  handleQuickSearch,
  placeholder = "Search",
  checkboxActions,
}: Props<L>) => {
  return (
    <GridToolbarContainer>
      <Grid item>
        <GridToolbarExport />
      </Grid>

      {showSearchBar && handleQuickSearch && (
        <Stack
          direction="row"
          width="100%"
          justifyContent="space-between"
          spacing={1}
        >
          {!!checkboxActions && (
            <MenuPopupButton
              actions={checkboxActions}
              sx={{
                padding: 0,
                minWidth: 38,
                marginLeft: 0.2,
                borderRadius: 100,
              }}
            />
          )}
          <TextField
            fullWidth
            placeholder={placeholder}
            onChange={debounce(handleQuickSearch, 300)}
          />
        </Stack>
      )}
    </GridToolbarContainer>
  );
};
