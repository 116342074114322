import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  User,
  Media,
  Project,
  Task,
  PaymoUser,
  Client,
  Ticket,
  Note,
  MilvusSearchResult,
  Org,
  Discussion,
  Comment,
  Topic,
  SlackForward,
  SlackUser,
  Device,
  Event,
  NfcUuid
} from "../types";

interface CountRow<R> {
  count: number;
  rows: R[];
}
type Users = CountRow<User>;
type AllMedia = CountRow<Media>;
type Projects = CountRow<Project>;
type Tasks = CountRow<Task>;
type PaymoUsers = CountRow<PaymoUser>;
type Clients = CountRow<Client>;
type SessionTasks = CountRow<Task>;
type Tickets = CountRow<Ticket>;
type Notes = CountRow<Note>;
type MilvusSearchResults = CountRow<MilvusSearchResult>;
type Orgs = CountRow<Org>;
type Discussions = CountRow<Discussion>;
type Topics = CountRow<Topic>;
type SlackForwards = CountRow<SlackForward>;
type SlackUsers = CountRow<SlackUser>;
type Devices = CountRow<Device>;
type Events = CountRow<Event>;
type NfcUuids = CountRow<NfcUuid>;
interface Stats {
  recentTasks: number;
  upcomingSessions: number;
}
export interface AdminState {
  users: Users;
  media: AllMedia;
  projects: Projects;
  tasks: Tasks;
  paymoUsers: PaymoUsers;
  clients: Clients;
  sessionTasks: SessionTasks;
  notes: Notes;
  tickets: Tickets;
  milvusRes: MilvusSearchResults;
  projectSessions: Task[];
  stats: Stats;
  orgs: Orgs;
  discussions: Discussions;
  discussion: Discussion | { comments: Comment[] };
  communityRes: Topics;
  slackForwards: SlackForwards;
  slackUsers: SlackUsers;
  devices: Devices;
  events: Events;
  nfcUuids: NfcUuids;
}

const initialState: AdminState = {
  users: {
    count: 0,
    rows: [],
  },
  media: {
    count: 0,
    rows: [],
  },
  projects: {
    count: 0,
    rows: [],
  },
  tasks: {
    count: 0,
    rows: [],
  },
  clients: {
    count: 0,
    rows: [],
  },
  paymoUsers: {
    count: 0,
    rows: [],
  },
  sessionTasks: {
    count: 0,
    rows: [],
  },
  projectSessions: [],
  notes: { count: 0, rows: [] },
  tickets: { count: 0, rows: [] },
  milvusRes: {
    count: 0,
    rows: [],
  },
  stats: {
    recentTasks: 0,
    upcomingSessions: 0,
  },
  orgs: {
    count: 0,
    rows: [],
  },
  discussions: {
    count: 0,
    rows: [],
  },
  discussion: { comments: [] },
  communityRes: { count: 0, rows: [] },
  slackForwards: { count: 0, rows: [] },
  slackUsers: { count: 0, rows: [] },
  devices: { count: 0, rows: [] },
  events: { count: 0, rows: [] },
  nfcUuids: {count: 0, rows: []}
};

export const admin = {
  ...createSlice({
    name: "admin",
    initialState,
    reducers: {
      setUsers(state, { payload }: PayloadAction<Users>) {
        state.users = payload;
      },
      setMedia(state, { payload }: PayloadAction<AllMedia>) {
        state.media = payload;
      },
      setProjects(state, { payload }: PayloadAction<Projects>) {
        state.projects = payload;
      },
      setTasks(state, { payload }: PayloadAction<Tasks>) {
        state.tasks = payload;
      },
      setPaymoUsers(state, { payload }: PayloadAction<PaymoUsers>) {
        state.paymoUsers = payload;
      },
      setClients(state, { payload }: PayloadAction<Clients>) {
        state.clients = payload;
      },
      setSessionTasks(state, { payload }: PayloadAction<SessionTasks>) {
        state.sessionTasks = payload;
      },
      setTickets(state, { payload }: PayloadAction<Tickets>) {
        state.tickets = payload;
      },
      setNotes(state, { payload }: PayloadAction<Notes>) {
        state.notes = payload;
      },
      setMilvusRes(state, { payload }: PayloadAction<MilvusSearchResults>) {
        state.milvusRes = payload;
      },
      setProjectSessions(state, { payload }: PayloadAction<{ tasks: Task[] }>) {
        state.projectSessions = payload.tasks;
      },
      setStats(state, { payload }: PayloadAction<Stats>) {
        state.stats = payload;
      },
      setOrgs(state, { payload }: PayloadAction<Orgs>) {
        state.orgs = payload;
      },
      setDiscussions(state, { payload }: PayloadAction<Discussions>) {
        state.discussions = payload;
      },
      setDiscussion(
        state,
        { payload }: PayloadAction<{ discussion: Discussion }>,
      ) {
        state.discussion = payload.discussion;
      },
      setCommunityRes(state, { payload }: PayloadAction<Topics>) {
        state.communityRes = payload;
      },
      setSlackForwards(state, { payload }: PayloadAction<SlackForwards>) {
        state.slackForwards = payload;
      },
      setSlackUsers(state, { payload }: PayloadAction<SlackUsers>) {
        state.slackUsers = payload;
      },
      setDevices(state, { payload }: PayloadAction<Devices>) {
        state.devices = payload;
      },
      setEvents(state, { payload }: PayloadAction<Events>) {
        state.events = payload
      },
      setNfcUuids(state, { payload }: PayloadAction<NfcUuids>) {
        state.nfcUuids = payload;
      }
    },
  }),
  persist: false,
};
