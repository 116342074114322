import { adminActions, adminSelectors } from "../../state";
import { ORGS_COLUMNS } from "../../components/tables/columns";
import GenericSSPPage from "../../components/tables/GenericServerSidePage";

export default function OrgsPage() {
  return (
    <GenericSSPPage
      adminAction={adminActions.getSSPOrgs}
      rowsSelector={adminSelectors.orgs}
      dataDisplayName="Orgs"
      columns={ORGS_COLUMNS}
    />
  );
}
