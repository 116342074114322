import { CancelSession } from "./CancelSession";
import { ConfirmSession } from "./ConfirmSession";
import { HomePage } from "./HomePage";
import { NotAuthorizedPage } from "./NotAuthorizedPage";
import { NotFoundPage } from "./NotFoundPage";

export const MainPages = {
  home: {
    anon: true,
    path: "/",
    title: "Home",
    type: "PAGE_HOME",
    view: HomePage,
  },
  confirmSession: {
    anon: false,
    path: "/session/confirm/:id",
    title: "Confirm Session",
    view: ConfirmSession,
  },
  cancelSession: {
    anon: false,
    path: "/session/cancel/:id",
    title: "Confirm Session",
    view: CancelSession,
  },
  notAuthorized: {
    anon: true,
    path: "unauthorized",
    title: "Not Authorized",
    type: "PAGE_NOT_AUTHORIZED",
    view: NotAuthorizedPage,
  },
  notFound: {
    anon: true,
    path: "*",
    title: "Page Not Found",
    type: "PAGE_NOT_FOUND",
    view: NotFoundPage,
  },
};
