import React, { useEffect } from "react";
import { Backdrop, CircularProgress, CssBaseline } from "@mui/material";
import { authSelectors, uiSelectors, useSelector } from "../../state";
import MiniDrawer from "./MiniDrawer";
import PermanentDrawer from "./PermanentDrawer";
import MobileDrawer from "./MobileDrawer";
import Notification from "../../components/Notification";
import { Outlet } from "react-router-dom";
import { COLLAPSIBLE_DRAWER } from "../../config";
import { MainContentStyled } from "./AdminLayout.styles";
import { useMobile } from "../../themes";
import MobileTopBar from "./MobileTopBar";
import { Navigation } from "../../lib";

export const AdminLayout = React.memo(function () {
  const loading = useSelector(uiSelectors.loading);
  const menuOpen = useSelector(uiSelectors.menuOpen);
  const isMobile = useMobile();
  const requiresMfa = useSelector(authSelectors.requiresMfa);
  useEffect(() => {
    if (requiresMfa === true) {
      Navigation.go("/auth/multifactor-auth");
    }
  }, [requiresMfa]);

  return (
    <>
      <CssBaseline />
      {isMobile ? (
        <>
          <MobileTopBar />
          <MobileDrawer />
        </>
      ) : COLLAPSIBLE_DRAWER ? (
        <MiniDrawer />
      ) : (
        <PermanentDrawer />
      )}
      {/* Figure out if the ml is always correct feels hacky */}
      <MainContentStyled
        sx={{
          ml: isMobile ? 0 : menuOpen ? 36 : 14,
          mr: isMobile ? 0 : 6,
        }}
      >
        <Outlet />
      </MainContentStyled>
      <Notification />
      {loading && (
        <Backdrop open={true}>
          <CircularProgress size={128} />
        </Backdrop>
      )}
    </>
  );
});
