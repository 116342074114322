import { useEffect, useState } from "react";
import { DataGridPro } from "@mui/x-data-grid-pro";
import {
  useDispatch,
  adminActions,
  useSelector,
  adminSelectors,
} from "../../state";
import { Box, Typography } from "@mui/material";
import { RootListViewStyled } from "./GenericPage.styles";
import { ToolBar } from "../../components/tables/ToolBar";
import { SESSIONS_DETAILS_COLUMNS } from "../../components/tables/columns";

export default function SessionsDetailsPage() {
  const { rows, count } = useSelector(adminSelectors.projects);
  const dispatch = useDispatch();
  const [pageSize, setPageSize] = useState(25);

  useEffect(() => {
    dispatch(adminActions.getProjects());
  }, [dispatch]);

  return (
    <RootListViewStyled>
      <Typography variant="h3" mb={3}>
        Session Details
      </Typography>
      {count ? (
        <DataGridPro
          autoHeight
          rows={rows}
          columns={SESSIONS_DETAILS_COLUMNS}
          disableSelectionOnClick
          rowsPerPageOptions={[10, 25, 50, 100, 250, 500, 1000]}
          pageSize={pageSize}
          onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
          pagination
          components={{
            Toolbar: ToolBar,
          }}
        />
      ) : (
        <Box display={"flex"} justifyContent={"center"}>
          <Typography fontSize={24} fontWeight={"bold"} color={"forestgreen"}>
            There are no Sessions
          </Typography>
        </Box>
      )}
    </RootListViewStyled>
  );
}
