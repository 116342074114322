import { useEffect } from "react";
import { Typography } from "@mui/material";
// Local
import { Navigation } from "../../lib";
import { authActions, useAction } from "../../state";
import { AuthPages } from "./";

export const LogoutPage = () => {
  const logout = useAction(authActions.logout);

  useEffect(() => {
    logout().then(() => {
      Navigation.reload(AuthPages.login);
    });
  }, [logout]);

  return <Typography variant="h2">Logging out...</Typography>;
};
