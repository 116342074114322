import { useState } from "react";
import BasicTabs from "../../components/Tabs";
import {
  Box,
  Button,
  Stack,
  TextField,
  Typography,
  styled,
} from "@mui/material";
import {
  ChatResults,
  CommunityResults,
  TicketResults,
} from "../../components/searchResults";

const StyledBox = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  height: "90vh",
  padding: theme.spacing(3),
  [theme.breakpoints.down("sm")]: {
    padding: theme.spacing(1),
  },
}));

const ResultContainer = styled(Box)(({ theme }) => ({
  width: "100%",
  padding: theme.spacing(2),
  borderRadius: 12,
}));

const sx = {
  height: "calc(100vh - 320px)",
  overflowY: "auto",
};

export function SearchAllPage() {
  const [input, setInput] = useState("");
  const [search, setSearch] = useState("");
  return (
    <StyledBox>
      <Typography variant="h4" align="center" color="primary">
        Search All
      </Typography>
      <ResultContainer>
        <form>
          <Stack
            direction="row"
            justifyContent="center"
            alignItems="center"
            marginBottom={2}
            spacing={1}
          >
            <TextField
              value={input}
              onChange={(event) => setInput(event.target.value)}
              variant="outlined"
              label="Enter a search term"
              fullWidth
              sx={{ maxWidth: "60%" }}
            />
            <Button
              type="submit"
              onClick={() => {
                setSearch(input);
                setInput("");
              }}
              variant="contained"
              color="primary"
              disabled={!input}
            >
              Search
            </Button>
          </Stack>
        </form>
        <BasicTabs
          panels={[
            {
              label: "Community",
              component: (hide) => (
                <CommunityResults
                  search={search}
                  hide={hide}
                  sx={sx}
                ></CommunityResults>
              ),
            },
            {
              label: "Tickets",
              component: (hide) => (
                <TicketResults
                  search={search}
                  hide={hide}
                  limit={25}
                  sx={sx}
                ></TicketResults>
              ),
            },
            {
              label: "Chatbot",
              component: (hide) => (
                <ChatResults search={search} hide={hide} sx={sx}></ChatResults>
              ),
            },
          ]}
        />
      </ResultContainer>
    </StyledBox>
  );
}
