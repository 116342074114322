import { styled } from "@mui/material/styles";

export const RootStyle = styled(`div`)(({ theme }) => ({
  backgroundRepeat: "no-repeat",
  backgroundPosition: "bottom center",
  backgroundSize: "100%",
  display: "flex",
  height: "100vh",
  [theme.breakpoints.up("sm")]: {
    flexDirection: "row",
  },
  [theme.breakpoints.down("sm")]: {
    justifyContent: "space-between",
    flexDirection: "column",
  },
}));

export const LeftPanel = styled(`div`)(({ theme }) => ({
  backgroundColor: theme.palette.primary.main,
  padding: 30,
  display: "flex",
  justifyContent: "center",
  "& img": {
    height: "auto",
    maxWidth: "66%",
  },
  [theme.breakpoints.up("md")]: {
    height: "100vh",
    width: "66%",
    paddingTop: 120,
    "& img": {
      height: 120,
    },
  },
}));

export const RightPanel = styled(`div`)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  padding: 8,
  marginBottom: 100,
  maxWidth: "calc(100vw - 8px)",
  [theme.breakpoints.up("md")]: {
    height: "auto",
    minWidth: 520,
    width: "34%",
  },
}));
export const OutletPanel = styled(`div`)(() => ({
  width: 360,
}));
