import { useEffect, useState } from "react";
import { GridColDef, GridRenderCellParams } from "@mui/x-data-grid";
import { useDispatch, useSelector } from "react-redux";
import { adminActions, adminSelectors } from "../../state";
import { Link } from "react-router-dom";
import AdminPages from ".";
import { Button, IconButton } from "@mui/material";
import { AddIcon, ConfirmDeleteDialog } from "../../components";
import { ActionBox, RootListViewStyled } from "./GenericPage.styles";
import { Create } from "@mui/icons-material";
import { format } from "date-fns";
import { useMobile } from "../../themes";
import { ServerDataGrid } from "../../components/tables/ServerDataGrid";

export const UsersPage = () => {
  const dispatch = useDispatch();
  const users = useSelector(adminSelectors.users);
  const rowCount = useSelector(adminSelectors.usersCount);

  const isMobile = useMobile();
  const columns: GridColDef[] = [
    { field: "id", headerName: "Id", type: "number", width: 150 },
    {
      field: "first_name",
      headerName: "First Name",
      // test for 'is'
      type: "singleSelect",
      valueOptions: ["shua", "john"],
      width: 150,
    },
    {
      field: "last_name",
      headerName: "Last Name",
      type: "string",
      width: 150,
    },
    {
      field: "TEST_NON_EXIST_FIELD",
      headerName: "Email",
      type: "string",
      width: 200,
      valueGetter: (params) => params.row.email,
    },
    {
      field: "created_at",
      headerName: "Created at",
      type: "dateTime",
      width: 120,
    },
    {
      field: "login_attempts",
      headerName: "Login Count",
      type: "number",
      width: 120,
    },
    {
      field: "last_login",
      headerName: "Last Login",
      width: 180,
      valueFormatter: (v) =>
        v && typeof v.value === "string" ? format(new Date(v.value), "Pp") : "",
    },
    { field: "last_failed_login", headerName: "Last Failed Login", width: 150 },
    // to filter by and sorting a column referencing another table use dot notation
    {
      field: "roles.internal_name",
      headerName: "Roles",
      width: 200,
      valueGetter: (params) => params.row.roles.map((r) => r.name).join(", "),
      //  sortable: false,
    },
    {
      field: "actions",
      headerName: "Actions",
      width: 150,
      renderCell: (params: GridRenderCellParams<number>) => (
        <>
          <Link
            to={AdminPages.editUsers.path.replace(":id", params.id.toString())}
          >
            <IconButton color="primary" size="small">
              <Create />
            </IconButton>
          </Link>

          <ConfirmDeleteDialog
            handleConfirm={() => dispatch(adminActions.deleteUser(params.id))}
          />
        </>
      ),
    },
  ];
  useEffect(() => {
    dispatch(adminActions.getUsers(""));
  }, [dispatch]);

  const [tableStateParams, setTableStateParams] = useState("");

  useEffect(() => {
    if (tableStateParams) {
      dispatch(adminActions.getUsers(tableStateParams));
    }
  }, [dispatch, tableStateParams]);

  return (
    <RootListViewStyled>
      <ActionBox>
        <Link
          style={{ width: isMobile ? "100%" : undefined }}
          to={AdminPages.createUsers.path}
        >
          <Button fullWidth variant="outlined" color="primary">
            <AddIcon /> Add User
          </Button>
        </Link>
      </ActionBox>
      <ServerDataGrid
        rowCount={rowCount}
        rows={users.rows}
        columns={columns}
        setTableStateParams={setTableStateParams}
        toolbarProps={{
          showSearchBar: true,
          placeholder: "Search for user",
        }}
      />
    </RootListViewStyled>
  );
};
