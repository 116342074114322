import { Dialog, DialogTitle, IconButton } from "@mui/material";
import { DialogContent } from "@mui/material";
import { Button } from "@mui/material";
import { DialogContentText } from "@mui/material";
import { DialogActions } from "@mui/material";
import React from "react";
import { Delete } from "@mui/icons-material";

interface ConfirmDialogProps {
  handleConfirm: () => void;
}

export function ConfirmDeleteDialog(props: ConfirmDialogProps) {
  const [open, setOpen] = React.useState(false);

  const handleConfirm = () => {
    props.handleConfirm();
    setOpen(false);
  };

  return (
    <>
      <IconButton
        onClick={() => {
          setOpen(true);
        }}
        color="error"
        size="small"
      >
        <Delete />
      </IconButton>
      <Dialog open={open}>
        <DialogTitle>Delete Row</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to delete this?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setOpen(false);
            }}
            autoFocus
          >
            Cancel
          </Button>
          <Button onClick={handleConfirm}>Delete</Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
