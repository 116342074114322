import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router";
import { mainActions, mainSelectors, useDispatch } from "../../state";

export function ConfirmSession() {
  const params = useParams();
  const { id } = params;
  const dispatch = useDispatch();
  const message = useSelector(mainSelectors.statusMessage);

  useEffect(() => {
    if (id) {
      dispatch(mainActions.confirmSession(id));
    }
  }, [dispatch]);

  return (
    <div className="paper">
      <h1>{message}</h1>
    </div>
  );
}
