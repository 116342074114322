import { AppThunk, Ticket, User } from "..";
import { Navigation } from "../../lib";
import AdminPages from "../../pages/admin";
import { authActions, authClient, uiActions } from "../states";
import { admin } from "./state";

const { actions } = admin;

const action = {
  get:
    (
      url: string,
      action: keyof typeof actions,
      nameForMessage?: string,
    ): AppThunk =>
    async (dispatch) => {
      dispatch(uiActions.setLoading(true));
      const { data, status } = await authClient.get(url);
      dispatch(uiActions.setLoading(false));
      if (status === 200) {
        dispatch(actions[action](data));
        return data;
      } else {
        dispatch(
          uiActions.showError(
            nameForMessage ? `Get ${nameForMessage} failed` : "Failed",
          ),
        );
        return { data: [], name: "Report failed" };
      }
    },
  create:
    (
      url: string,
      body: any,
      nameForMessage?: string,
      onSuccessCB?: (dispatch: any) => void,
    ): AppThunk =>
    async (dispatch) => {
      dispatch(uiActions.setLoading(true));
      const { status } = await authClient.post(url, body);
      dispatch(uiActions.setLoading(false));
      if (status === 204) {
        dispatch(
          uiActions.showSuccess(
            nameForMessage ? nameForMessage + " saved" : "saved",
          ),
        );
        onSuccessCB && onSuccessCB(dispatch);
      } else {
        dispatch(
          uiActions.showError("failed to save " + (nameForMessage || "")),
        );
      }
    },
  update:
    (
      url: string,
      body: any,
      nameForMessage?: string,
      onSuccessCB?: (dispatch: any) => void,
    ): AppThunk =>
    async (dispatch) => {
      dispatch(uiActions.setLoading(true));
      const { status } = await authClient.put(url, body);
      dispatch(uiActions.setLoading(false));
      if (status === 204) {
        dispatch(
          uiActions.showSuccess(
            nameForMessage ? nameForMessage + " updated" : "updated",
          ),
        );
        onSuccessCB && onSuccessCB(dispatch);
      } else {
        dispatch(
          uiActions.showError("failed to save " + (nameForMessage || "")),
        );
      }
    },
  delete:
    (
      url: string,
      nameForMessage?: string,
      onSuccessCB?: (dispatch: any) => void,
    ): AppThunk =>
    async (dispatch) => {
      dispatch(uiActions.setLoading(true));
      const { status } = await authClient.delete(url);
      dispatch(uiActions.setLoading(false));
      if (status === 204) {
        dispatch(uiActions.showSuccess((nameForMessage || "") + " deleted"));
        onSuccessCB && onSuccessCB(dispatch);
      } else {
        dispatch(
          uiActions.showError("failed to delete " + (nameForMessage || "")),
        );
      }
    },
};

export const adminActions = {
  ...actions,
  getUsers(params: string) {
    return action.get("/users?" + params, "setUsers", "Get users");
  },
  getUser(id): AppThunk<Promise<User>> {
    return async (dispatch) => {
      dispatch(uiActions.setLoading(true));
      const { data, status } = await authClient.get(`/users/` + id);
      dispatch(uiActions.setLoading(false));
      if (status !== 200) {
        dispatch(uiActions.showError("failed to get user"));
        return false;
      } else {
        return data;
      }
    };
  },
  createUser(values) {
    return action.create("/users", values, "user", (dispatch) => {
      dispatch(adminActions.getUsers(""));
      Navigation.go(AdminPages.users.path);
    });
  },
  updateUser(id, values): AppThunk {
    return action.update("/users/" + id, values, "user", (dispatch) => {
      dispatch(adminActions.getUsers(""));
      Navigation.go(AdminPages.users.path);
    });
  },
  deleteUser(id): AppThunk {
    return action.delete("/users/" + id, "user", (dispatch) => {
      dispatch(adminActions.getUsers(""));
    });
  },
  updateProfile(values): AppThunk {
    return async (dispatch) => {
      dispatch(uiActions.setLoading(true));
      const { status } = await authClient.put(`/profile`, values);
      if (status === 204) {
        dispatch(uiActions.showSuccess("Profile updated"));
        const user = await dispatch(adminActions.getUser(values.id));
        dispatch(authActions.loadUserValues(user, values.roles));
        Navigation.go(AdminPages.dashboard.path);
      } else {
        dispatch(uiActions.showError("Profile update failed"));
      }
      dispatch(uiActions.setLoading(false));
    };
  },
  /** MEDIA START*/
  getMedia(params: string): AppThunk {
    return async (dispatch) => {
      dispatch(uiActions.setLoading(true));
      const { data, status } = await authClient.get(`/media?${params}`);
      if (status === 200) {
        dispatch(actions.setMedia(data));
      } else {
        dispatch(uiActions.showError("Get media failed"));
      }
      dispatch(uiActions.setLoading(false));
    };
  },
  saveMedia(v, isBulkUpload: boolean): AppThunk {
    return async (dispatch, getState) => {
      dispatch(uiActions.setLoading(true));
      const { token } = getState().auth;
      const formData = new FormData();
      formData.append("file", v.file[0]);
      let url = "";
      if (isBulkUpload) {
        url = `media/bulk-upload`;
      } else {
        url = `media?name=${v.name}&label=${v.label}`;
      }
      try {
        const { status } = await authClient.post(url, formData, {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "multipart/form-data",
          },
        });
        if (status === 200) {
          dispatch(uiActions.showSuccess(" Media Saved"));
        } else {
          dispatch(uiActions.showError("Save Media failed"));
        }
      } catch (error) {
        dispatch(uiActions.showError("Save Media failed"));
      }

      dispatch(uiActions.setLoading(false));
    };
  },
  deleteMedia(id): AppThunk {
    return async (dispatch) => {
      dispatch(uiActions.setLoading(true));
      const { status } = await authClient.delete(`/media/${id}`);
      if (status === 204) {
        dispatch(uiActions.showSuccess("media deleted"));
        dispatch(adminActions.getMedia(""));
      } else {
        dispatch(uiActions.showError("failed to delete media"));
      }
      dispatch(uiActions.setLoading(false));
    };
  },

  /**MEDIA END */
  getProjects(): AppThunk {
    return async (dispatch) => {
      dispatch(uiActions.setLoading(true));
      const { data, status } = await authClient.get("/paymo/projects");
      dispatch(uiActions.setLoading(false));
      if (status !== 200) {
        dispatch(uiActions.showError("failed to get reports"));
        return { data: [], name: "Report failed" };
      } else {
        dispatch(actions.setProjects(data));
        return data;
      }
    };
  },
  getPaymoUsers(): AppThunk {
    return async (dispatch) => {
      dispatch(uiActions.setLoading(true));
      const { data, status } = await authClient.get("/paymo/users");
      dispatch(uiActions.setLoading(false));
      if (status !== 200) {
        dispatch(uiActions.showError("failed to get reports"));
        return { data: [], name: "Report failed" };
      } else {
        dispatch(actions.setPaymoUsers(data));
        return data;
      }
    };
  },
  getDiscussion(id: string): AppThunk {
    return action.get("/paymo/discussion/" + id, "setDiscussion", "discussion");
  },
  syncDiscussions(params = ""): AppThunk {
    return async (dispatch) => {
      dispatch(uiActions.setLoading(true));
      const { status } = await authClient.post("/paymo/sync_discussions", {});
      dispatch(uiActions.setLoading(false));
      if (status === 204) {
        dispatch(adminActions.getDiscussions(params));
      } else {
        dispatch(uiActions.showError("Sync Failed"));
      }
    };
  },
  getClients(): AppThunk {
    return async (dispatch) => {
      dispatch(uiActions.setLoading(true));
      const { data, status } = await authClient.get("/paymo/clients");
      dispatch(uiActions.setLoading(false));
      if (status !== 200) {
        dispatch(uiActions.showError("failed to get reports"));
        return { data: [], name: "Report failed" };
      } else {
        dispatch(actions.setClients(data));
        return data;
      }
    };
  },
  getSessionTasks(project_id: string | number): AppThunk {
    return async (dispatch) => {
      dispatch(uiActions.setLoading(true));
      const { data, status } = await authClient.get(
        `/paymo/sessions/${project_id}`,
      );
      dispatch(uiActions.setLoading(false));
      if (status !== 200) {
        dispatch(uiActions.showError("failed to get reports"));
        return { data: [], name: "Report failed" };
      } else {
        dispatch(actions.setSessionTasks(data));
        return data;
      }
    };
  },
  getNotes(): AppThunk {
    return async (dispatch) => {
      dispatch(uiActions.setLoading(true));
      const { data, status } = await authClient.get("notes");
      dispatch(uiActions.setLoading(false));
      if (status !== 200) {
        dispatch(uiActions.showError("failed to get notes"));
        return { data: [], name: "Report failed" };
      } else {
        dispatch(actions.setNotes(data));
        return data;
      }
    };
  },
  getTicketNotes(ticket_id: string): AppThunk<Promise<Ticket>> {
    return async (dispatch) => {
      dispatch(uiActions.setLoading(true));
      const { data, status } = await authClient.get(
        "ticket/" + ticket_id + "/notes",
      );
      dispatch(uiActions.setLoading(false));
      if (status !== 200) {
        dispatch(uiActions.showError("failed to get ticket"));
      } else {
        return data;
      }
    };
  },
  getSearchMilvusRes(search: string, limit = 100): AppThunk {
    return async (dispatch) => {
      dispatch(uiActions.setLoading(true));
      const { data, status } = await authClient.get(
        "tickets/search_milvus?search=" + search + "&limit=" + limit,
      );
      dispatch(uiActions.setLoading(false));
      if (status !== 200) {
        dispatch(uiActions.showError("failed to get tickets"));
      } else {
        dispatch(actions.setMilvusRes(data));
        return data;
      }
    };
  },
  getSearchCommunityRes(search: string, category: string = "all"): AppThunk {
    return action.get(
      "/tickets/search_community?search=" + search + "&category=" + category,
      "setCommunityRes",
    );
  },
  getProjectSessions(project_id: string): AppThunk {
    return async (dispatch) => {
      dispatch(uiActions.setLoading(true));
      const { data, status } = await authClient.get(
        `/paymo/sessions/${project_id}`,
      );
      dispatch(uiActions.setLoading(false));
      if (status !== 200) {
        dispatch(uiActions.showError("failed to get sessions"));
      } else {
        dispatch(actions.setProjectSessions(data));
        return data;
      }
    };
  },
  getMyStats(): AppThunk {
    return async (dispatch) => {
      dispatch(uiActions.setLoading(true));
      const { data, status } = await authClient.get(`/stats/mine`);
      dispatch(uiActions.setLoading(false));
      if (status !== 200) {
        dispatch(uiActions.showError("failed to get stats"));
      } else {
        dispatch(actions.setStats(data));
        return data;
      }
    };
  },
  uploadPaymoApiKey(paymo_api_key: string, onSuccess?: () => void): AppThunk {
    return async (dispatch) => {
      dispatch(uiActions.setLoading(true));
      const { status } = await authClient.put("/upload_paymo_api_key", {
        paymo_api_key,
      });
      dispatch(uiActions.setLoading(false));
      if (status !== 204) {
        dispatch(uiActions.showError("failed to upload api key"));
      } else {
        onSuccess && onSuccess();
      }
    };
  },
  getOrgs(): AppThunk {
    return async (dispatch) => {
      dispatch(uiActions.setLoading(true));
      const { data, status } = await authClient.get(`/orgs`);
      dispatch(uiActions.setLoading(false));
      if (status !== 200) {
        dispatch(uiActions.showError("failed to get orgs"));
      } else {
        dispatch(actions.setOrgs(data));
        return data;
      }
    };
  },
  createOrgUuid(org_id: number, uuid: string) {
    return action.create("/uuids", { org_id, uuid }, "UUID", (dispatch) => {
      dispatch(adminActions.getNfcUuids());
    });
  },
  postFeedback<T extends "thumb" | "feedback">(feedback: {
    type: T;
    feedback: T extends "thumb" ? "up" | "down" : string;
    order_num: number;
    chat_id: string;
  }): AppThunk {
    return async (dispatch) => {
      dispatch(uiActions.setLoading(true));
      const { status } = await authClient.post(
        "/admire_chatbot/" + feedback.type,
        { thumb: feedback.feedback, ...feedback },
      );
      dispatch(uiActions.setLoading(false));
      if (status !== 204) {
        dispatch(uiActions.showError("failed to post feedback"));
      }
    };
  },
  getSlackForwards(): AppThunk {
    return action.get("/slack/forwarding", "setSlackForwards");
  },
  createSlackForward(values: any): AppThunk {
    return action.create("/slack/forwarding", values, "forward", (dispatch) => {
      dispatch(adminActions.getSlackForwards());
    });
  },
  getSlackUsers(): AppThunk {
    return action.get("/slack/users", "setSlackUsers", "slack users");
  },
  deleteForward(id: string) {
    return action.delete("/slack/forwarding/" + id);
  },
  deleteForwardsByToOrFrom(toOrFrom: "to" | "from", id: string) {
    return action.delete("/slack/forwarding?forward_" + toOrFrom + "=" + id);
  },
  createOrgDevices(values) {
    return action.create("/org_devices", values);
  },
  linkDevicesToOrgs(values: { org_id: number; devices: number[] }) {
    return action.update("/link_org_devices", values, "links", (dispatch) => {
      dispatch(adminActions.getDevices());
    });
  },
  getCsProjects() {
    return action.get("/paymo/cs_projects", "setProjects");
  },
  createEventTasks(values: { project_id: number; events: string[] }) {
    return action.create(
      "/calendly/create_event_tasks",
      values,
      "",
      (dispatch) => {
        dispatch(adminActions.getEvents());
      },
    );
  },
  updateOrg(id: number, values: { subdomain: string }) {
    return action.update("/orgs/" + id, values, "org", (dispatch) => {
      dispatch(adminActions.getSSPOrgs());
    });
  },
  updateUuid(id: number, values: { org_id; uuid }) {
    return action.update("/uuid/" + id, values, "uuid", (dispatch) => {
      dispatch(adminActions.getNfcUuids());
    });
  },
  deleteUuid(id: number) {
    return action.delete("/uuid/" + id, "uuid", (dispatch) => {
      dispatch(adminActions.getNfcUuids());
    });
  },
  //server side pagination
  getDevices(params = "") {
    return action.get("/ssp/devices?" + params, "setDevices", "devices");
  },
  getTickets(params = "") {
    return action.get("/ssp/tickets?" + params, "setTickets", "tickets");
  },
  getDiscussions(params = "") {
    return action.get(
      "/ssp/discussions?" + params,
      "setDiscussions",
      "discussions",
    );
  },
  getTasks(params = "") {
    return action.get("/ssp/tasks?" + params, "setTasks", "tasks");
  },
  getEvents(params = "") {
    return action.get("/ssp/events?" + params, "setEvents", "events");
  },
  getSSPOrgs(params = "") {
    return action.get("/ssp/orgs?" + params, "setOrgs", "orgs");
  },
  getNfcUuids(params = "") {
    return action.get("/ssp/uuids?" + params, "setNfcUuids", "nfc uuids");
  },
};
