import { useEffect } from "react";
import {
  Typography,
  Box,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  SxProps,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { styled } from "@mui/system";
import { useSelector } from "react-redux";
import { adminActions, adminSelectors } from "../../state";
import { useDispatch } from "react-redux";
import { ChatMessage } from "../../components/text/ChatMessages";

export const ChatContainer = styled(Box)(({ theme }) => ({
  width: "100%",
  padding: theme.spacing(2),
  borderRadius: 12,
}));

const HtmlContentWrapper = styled("div")({
  "& p": {
    margin: 0,
  },
  "& .meta": {
    display: "none",
  },
  "& br": {
    display: "none",
  },
});
type Category =
  | "all"
  | "parent_portal"
  | "adrenaline"
  | "admire_unlimited"
  | "admire_desktop";
interface SearchCommunityProps {
  search: string;
  catFilter?: Category;
  hide?: boolean;
  sx?: SxProps;
}

const CommunityResults = ({
  search,
  catFilter,
  hide,
  sx,
}: SearchCommunityProps) => {
  const dispatch = useDispatch();
  const { rows } = useSelector(adminSelectors.communityRes);

  useEffect(() => {
    if (search)
      dispatch(adminActions.getSearchCommunityRes(search, catFilter || "all"));
  }, [search]);

  return (
    <Box sx={sx}>
      {!hide &&
        rows.map((topic, index) => {
          return (
            <Accordion key={index}>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography>{topic.title}</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <ChatContainer>
                  {topic.posts.map(
                    ({ cooked, staff, name, id, trust_level }, idx, posts) => (
                      <ChatMessage
                        key={id}
                        maxWidth="90%"
                        me={!!staff || trust_level === 4}
                        name={name}
                        prevMessage={
                          idx > 0
                            ? {
                                me: !!posts[idx - 1].staff,
                                name: posts[idx - 1].name,
                              }
                            : undefined
                        }
                      >
                        {
                          <HtmlContentWrapper
                            dangerouslySetInnerHTML={{
                              __html: cooked,
                            }}
                          />
                        }
                      </ChatMessage>
                    ),
                  )}
                </ChatContainer>
              </AccordionDetails>
            </Accordion>
          );
        })}
    </Box>
  );
};

export default CommunityResults;
