import React, { useLayoutEffect, useRef, useState } from "react";
import { Field } from "formik";
import { TextField } from "formik-mui";
import {
  //
  Button,
  Box,
  Link,
  Typography,
} from "@mui/material";
import { Form, RouterLink, useFormik, yup } from "../../lib";
import {
  authActions,
  authSelectors,
  useAction,
  useSelector,
} from "../../state";
import {
  ContainerStyle,
  FormStyle,
  TextFlexStyle,
  CenterElementStyle,
} from "./Auth.styles";
import { GoogleIdentityAuth } from "../../components/buttons/GoogleIdentitySignIn";
import { MSALSignIn } from "../../components/buttons/MSALSignIn";

const useResize = (myRef) => {
  const [width, setWidth] = useState(0);
  const [height, setHeight] = useState(0);
  useLayoutEffect(() => {
    setWidth(myRef.current.offsetWidth);
    setHeight(myRef.current.offsetHeight);
  }, [myRef]);

  return { width, height };
};

const validationSchema = yup.object({
  password: yup
    .string()
    .min(8, "Password should be of minimum 8 characters length")
    .required("Password is required"),
});

export const LoginPage = React.memo(
  /**
   *
   */
  function LoginPage() {
    const login = useAction(authActions.login);
    const frm = useFormik({
      initialValues: {
        username: "",
        password: "",
      },
      validationSchema,
      async onSubmit(values) {
        const success = await login(values);
        if (!success) {
          frm.setFieldValue("password", "");
        }
      },
    });
    const targetRef = useRef<HTMLHeadingElement>(null);
    const { width } = useResize(targetRef);
    const isLoggedIn = useSelector(authSelectors.isLoggedIn);

    return (
      <ContainerStyle>
        <Box>
          <Box>
            <Typography variant="h3" gutterBottom>
              Welcome
            </Typography>
            <Typography variant="body1" gutterBottom>
              Sign in to your account
            </Typography>
          </Box>
          <FormStyle>
            <Form form={frm}>
              <TextFlexStyle>
                <Field
                  component={TextField}
                  autoFocus
                  fullWidth
                  name="username"
                  label="Username"
                />
              </TextFlexStyle>
              <TextFlexStyle>
                <Field
                  component={TextField}
                  fullWidth
                  name="password"
                  label="Password"
                  type="password"
                />
              </TextFlexStyle>
              <div ref={targetRef}>
                <Button
                  variant="contained"
                  type="submit"
                  disabled={frm.isSubmitting}
                  fullWidth
                >
                  Sign In
                </Button>
              </div>
              <Box sx={{ mt: 2.5, mb: 2.5 }}>
                {!isLoggedIn && <GoogleIdentityAuth width={width} />}
              </Box>
              <Box sx={{ mt: 2.5, mb: 8, cursor: "pointer" }}>
                <MSALSignIn width={width} />
              </Box>
              <CenterElementStyle>
                <Link
                  component={RouterLink}
                  to={`/auth/forgot-password?email=${encodeURIComponent(
                    frm.values.username,
                  )}`}
                >
                  Forgot Password?
                </Link>
              </CenterElementStyle>
            </Form>
          </FormStyle>
        </Box>
      </ContainerStyle>
    );
  },
);
