import React, { useEffect } from "react";
import { Box, Card, Grid, Typography, styled } from "@mui/material";
import {
  useSelector,
  AppState,
  adminSelectors,
  useDispatch,
  adminActions,
} from "../../state";

export const StyledCard = styled(Card)(() => ({
  width: "100%",
  height: 200,
  padding: 24,
  textAlign: "center",
  margin: "0 auto",
  maxWidth: "90vw",
}));

export const StyledTitle = styled(Typography)(({ theme }) => ({
  color: theme.palette.primary.main,
  fontSize: 36,
  fontWeight: 700,
  marginBottom: 32,
  textTransform: "uppercase",
  lineHeight: 1,
}));
export const StyledContent = styled(Typography)(({ theme }) => ({
  color: theme.palette.secondary.main,
  fontSize: 24,
}));

export const DashboardPage = React.memo(function DashboardPage() {
  const dispatch = useDispatch();
  const firstName = useSelector(
    (state: AppState) => state.auth.user?.first_name,
  );
  const { recentTasks, upcomingSessions } = useSelector(adminSelectors.stats);

  useEffect(() => {
    dispatch(adminActions.getMyStats());
  }, [dispatch]);

  return (
    <Box>
      <Typography sx={{ mb: 4 }} variant="h3">
        Welcome {firstName}
      </Typography>
      <Grid container spacing={3}>
        <Grid item md={6} xs={12}>
          <StyledCard sx={{}}>
            <StyledTitle>Tasks - last 24 hours</StyledTitle>
            <StyledContent>{recentTasks}</StyledContent>
          </StyledCard>
        </Grid>
        <Grid item md={6} xs={12}>
          <StyledCard>
            <StyledTitle>Upcoming sessions</StyledTitle>
            <StyledContent>{upcomingSessions}</StyledContent>
          </StyledCard>
        </Grid>
      </Grid>
    </Box>
  );
});
