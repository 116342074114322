import { useState } from "react";
import { TextField, Button, Typography, Box, Stack } from "@mui/material";
import { styled } from "@mui/system";
import { TicketResults } from "../../components/searchResults";

const StyledBox = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  minHeight: "100vh",
  padding: theme.spacing(3),
  [theme.breakpoints.down("sm")]: {
    padding: theme.spacing(1),
  },
}));

export const ChatContainer = styled(Box)(({ theme }) => ({
  width: "100%",
  padding: theme.spacing(2),
  borderRadius: 12,
}));

const ResultContainer = styled(Box)(({ theme }) => ({
  width: "100%",
  padding: theme.spacing(2),
  borderRadius: 12,
}));

const SearchTicketsPage = () => {
  const [input, setInput] = useState("");
  const [search, setSearch] = useState("");

  return (
    <StyledBox>
      <Typography variant="h4" align="center" color="primary">
        Search Page
      </Typography>
      <ResultContainer>
        <form>
          <Stack
            direction="row"
            justifyContent="center"
            alignItems="center"
            marginBottom={2}
            spacing={1}
          >
            <TextField
              value={input}
              onChange={(event) => setInput(event.target.value)}
              variant="outlined"
              label="Enter a search term"
              fullWidth
              sx={{ maxWidth: "60%" }}
            />
            <Button
              type="submit"
              onClick={() => {
                setSearch(input);
                setInput("");
              }}
              variant="contained"
              color="primary"
              disabled={!input}
            >
              Search
            </Button>
          </Stack>
        </form>
        <TicketResults search={search} />
      </ResultContainer>
    </StyledBox>
  );
};

export default SearchTicketsPage;
