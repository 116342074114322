import { useEffect, useState } from "react";
import {
  useDispatch,
  adminActions,
  useSelector,
  adminSelectors,
  Org,
} from "../../state";
import { Button, Stack, TextField, Typography } from "@mui/material";
import { ActionBox } from "./GenericPage.styles";
import { NFC_UUIDS_COLUMNS } from "../../components/tables/columns";
import { AddIcon } from "../../components";
import Modal from "../../components/modal/Modal";
import { DropDown } from "../../components/inputs/DropDown";
import GenericSSPPage from "../../components/tables/GenericServerSidePage";

export default function NfcUuidsPage() {
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const { rows: orgs } = useSelector(adminSelectors.orgs);
  const [value, setValue] = useState<Org>();
  const [input, setInput] = useState("");

  useEffect(() => {
    dispatch(adminActions.getOrgs());
  }, [dispatch]);

  return (
    <>
      <ActionBox>
        <Button
          fullWidth
          variant="outlined"
          color="primary"
          onClick={() => {
            setOpen(true);
          }}
        >
          <AddIcon /> Add Uuid
        </Button>
      </ActionBox>
      <GenericSSPPage
        adminAction={adminActions.getNfcUuids}
        rowsSelector={adminSelectors.nfcUuids}
        dataDisplayName="Nfc Uuids"
        columns={NFC_UUIDS_COLUMNS}
      />
      <Modal open={open} onClose={() => setOpen(false)} sx={{ width: 518 }}>
        <Stack width={450} spacing={1.5} alignItems="center">
          <Typography marginBottom={2} color="primary">
            Add Nfc Uuid
          </Typography>
          <TextField
            onChange={(e) => setInput(e.target.value)}
            fullWidth
            placeholder="UUID"
          />
          <DropDown
            options={orgs}
            value={value}
            onValueChange={setValue}
            label="Orgs"
          />
          <Button
            color="primary"
            variant="contained"
            type="submit"
            disabled={!input || !value}
            onClick={() => {
              setOpen(false);
              setInput("");
              setValue(undefined);
              dispatch(adminActions.createOrgUuid(value!.id, input));
            }}
          >
            Submit
          </Button>
        </Stack>
      </Modal>
    </>
  );
}
