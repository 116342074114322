import { useState } from "react";
import {
  TextField,
  Button,
  Typography,
  Box,
  Stack,
  ToggleButtonGroup,
  ToggleButton,
} from "@mui/material";
import { styled } from "@mui/system";
import { CommunityResults } from "../../components/searchResults";

const StyledBox = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  minHeight: "100vh",
  padding: theme.spacing(3),
  [theme.breakpoints.down("sm")]: {
    padding: theme.spacing(1),
  },
}));

export const ChatContainer = styled(Box)(({ theme }) => ({
  width: "100%",
  padding: theme.spacing(2),
  borderRadius: 12,
}));

const ResultContainer = styled(Box)(({ theme }) => ({
  width: "100%",
  padding: theme.spacing(2),
  borderRadius: 12,
}));

const SearchCommunityPage = () => {
  const [input, setInput] = useState("");
  const [search, setSearch] = useState("");
  type Category =
    | "all"
    | "parent_portal"
    | "adrenaline"
    | "admire_unlimited"
    | "admire_desktop";
  const categoryFilters: Category[] = [
    "all",
    "admire_desktop",
    "adrenaline",
    "admire_unlimited",
    "parent_portal",
  ];
  const [catFilter, setCatFilter] = useState<Category>("all");

  return (
    <StyledBox>
      <Typography variant="h4" align="center" color="primary">
        Search Page
      </Typography>
      <ResultContainer>
        <Stack alignItems="center" justifyContent="center" marginBottom={3}>
          <ToggleButtonGroup
            color="primary"
            value={catFilter}
            onChange={(_e, value) => {
              setCatFilter(value);
            }}
            size="small"
            exclusive
            sx={{ flexWrap: "wrap" }}
          >
            {categoryFilters.map((cf) => (
              <ToggleButton value={cf}>
                <Typography variant="caption" width="16ch">
                  {cf
                    .split("_")
                    .map((word) => word[0].toUpperCase() + word.substring(1))
                    .join(" ")}
                </Typography>
              </ToggleButton>
            ))}
          </ToggleButtonGroup>
        </Stack>
        <form>
          <Stack
            direction="row"
            justifyContent="center"
            alignItems="center"
            marginBottom={2}
            spacing={1}
          >
            <TextField
              value={input}
              onChange={(event) => setInput(event.target.value)}
              variant="outlined"
              label="Enter a search term"
              fullWidth
              sx={{ maxWidth: "60%" }}
            />
            <Button
              type="submit"
              onClick={() => {
                setSearch(input);
                setInput("");
              }}
              variant="contained"
              color="primary"
              disabled={!input}
            >
              Search
            </Button>
          </Stack>
        </form>
        <CommunityResults
          search={search}
          catFilter={catFilter}
        ></CommunityResults>
      </ResultContainer>
    </StyledBox>
  );
};

export default SearchCommunityPage;
