
import { Field, FormikValues, useFormikContext } from "formik";
import { TextField } from "formik-mui";
import { useEffect, useRef } from "react";
import { useMobile } from "../../themes";

const MyTestField = (props) => {
  const fieldRef: any = useRef();
  return <TextField {...props} inputRef={fieldRef} />;
};
export const InputField = ({
  label,
  name,
  type = "text",
  disabled = false,
  helperText = "",
  checkForAutoComplete = false,
}) => {
  const { touched, errors, values, setFieldTouched } =
    useFormikContext<FormikValues>();
  const isMobile = useMobile();
  useEffect(() => {
    const inputs = window.document.querySelectorAll<HTMLInputElement>(
      ".MuiInputBase-input",
    );
    inputs.forEach((input: HTMLInputElement) => {
      let notAutoCompleted = true;
      if (input && checkForAutoComplete) {
        notAutoCompleted =
          window.getComputedStyle(input, ":-webkit-autofill")
            .backgroundColor === "rgba(0, 0, 0, 0)" ||
          window.getComputedStyle(input, ":-webkit-autofill")
            .backgroundColor === "rgb(0, 0, 0)" ||
          window.getComputedStyle(input, ":-webkit-autofill")
            .backgroundColor === "";
        if (!notAutoCompleted) {
          setFieldTouched(name, true, true);
          return;
        }
      }
    });
  }, [values[name]]);
  const showError = () => {
    return touched[name] && Boolean(errors[name]);
  };
  return (
    <Field
      component={MyTestField}
      fullWidth
      name={name}
      className={isMobile ? "mobile-root" : ".MuiTextField-root"}
      label={label}
      type={type}
      disabled={disabled}
      error={showError()}
      helperText={
        helperText === "" ? touched[name] && errors[name] : helperText
      }
    />
  );
};
