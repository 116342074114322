import { useParams } from "react-router-dom";
import { ChatMessage, ChatContainer } from "../../components/text/ChatMessages";
import {
  adminActions,
  adminSelectors,
  useDispatch,
  useSelector,
} from "../../state";
import { useEffect } from "react";
import { Box, styled } from "@mui/material";

const StyledBox = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  height: "90vh",
  padding: theme.spacing(3),
  justifyContent: "space-between",
  boxSizing: "border-box",
}));

export function DiscussionCommentsPage() {
  const dispatch = useDispatch();
  const { id } = useParams();
  const discussion = useSelector(adminSelectors.discussion);
  const { comments } = discussion;

  useEffect(() => {
    if (id) {
      dispatch(adminActions.getDiscussion(id));
    }
  }, [dispatch, id]);

  return (
    <StyledBox>
      <ChatContainer>
        {comments!.map((c, i, co) => (
          <ChatMessage
            me={c.paymo_user.type !== "Guest"}
            prevMessage={
              co[i + 1]
                ? {
                    me: co[i + 1].paymo_user.type !== "Guest",
                    name: co[i + 1].paymo_user.name,
                  }
                : undefined
            }
            key={i}
            name={c.paymo_user.name}
          >
            {<div dangerouslySetInnerHTML={{ __html: c.content as string }} />}
          </ChatMessage>
        ))}
      </ChatContainer>
    </StyledBox>
  );
}
