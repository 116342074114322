import { Box, Button, Stack, Checkbox, Typography } from "@mui/material";
import { Formik, Form, Field } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { adminActions, adminSelectors } from "../../state";
import { DateTimePicker, MultiSelectField } from "../inputs/Autocomplete";

interface Props {
  closeModal: () => void;
  id?: number;
}

export const SlackForwardForm = ({ closeModal }: Props) => {
  const initialValues: {
    from: string[];
    to: string[];
    send_to_original: boolean;
    start_time?: string;
    end_time?: string;
  } = {
    from: [],
    to: [],
    send_to_original: true,
  };
  const dispatch = useDispatch();
  const { rows } = useSelector(adminSelectors.slackUsers);
  const slackUsers = rows.map(({ id, real_name }) => ({
    id,
    label: real_name,
  }));

  const handleSubmit = (values) => {
    dispatch(adminActions.createSlackForward(values));
    closeModal();
  };

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      onSubmit={handleSubmit}
    >
      {({ isSubmitting, setFieldValue, values }) => (
        <Form style={{ width: "100%", height: "100%" }}>
          <Stack justifyContent="space-between" width="100%" height="100%">
            <Stack
              direction="column"
              justifyContent="space-evenly"
              spacing={4}
              alignItems="center"
              border="1px solid #E4E7EB"
              borderRadius={2}
              padding={2}
              height="100%"
            >
              <Stack direction="row" alignItems="center">
                <Typography>Continue Sending To Original Recipients</Typography>
                <Field
                  component={Checkbox}
                  label="Send To Original"
                  checked={values.send_to_original}
                  name="send_to_original"
                  value={values.send_to_original}
                  onChange={(e) => {
                    setFieldValue("send_to_original", e.target.checked);
                  }}
                ></Field>
              </Stack>
              <MultiSelectField
                initialValues={initialValues}
                label="Forward From"
                name="from"
                options={slackUsers.filter((su) => !values.to.includes(su.id))}
                sx={{ width: "80%" }}
              />
              <MultiSelectField
                initialValues={initialValues}
                label="Forward To"
                name="to"
                options={slackUsers.filter(
                  (su) => !values.from.includes(su.id),
                )}
                sx={{ width: "80%" }}
              />
              <Stack direction="row" width="100%" justifyContent="space-evenly">
                <DateTimePicker
                  label="Start (default - now)"
                  setFieldValue={setFieldValue}
                  values={values}
                  fieldName={"start_time"}
                  touched=""
                  errors=""
                />
                <DateTimePicker
                  label="End (default - indefinitely)"
                  setFieldValue={setFieldValue}
                  values={values}
                  fieldName={"end_time"}
                  touched=""
                  errors=""
                />
              </Stack>
            </Stack>
            <Box display={"flex"} width={"100%"} justifyContent={"flex-end"}>
              <Button
                sx={{ mt: 3, ml: 3, mr: 0 }}
                color="primary"
                variant="contained"
                type="submit"
                disabled={
                  isSubmitting || !values.from.length || !values.to.length
                }
              >
                Save Forwards
              </Button>
            </Box>
          </Stack>
        </Form>
      )}
    </Formik>
  );
};
