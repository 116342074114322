import {
  DataGridPro,
  GridColumns,
  GridValidRowModel,
  useGridApiRef,
} from "@mui/x-data-grid-pro";
import { Dispatch, SetStateAction, useState } from "react";
import { buildParams } from "../../lib/requestBuilder";
import { ToolBar } from "./ToolBar";

interface Props<L extends string> {
  rows: Record<string, any>[];
  columns: GridColumns<Record<string, any>>;
  rowCount: number;
  setTableStateParams: Dispatch<SetStateAction<string>>;
  showToolbar?: boolean;
  checkboxActions?: {
    labels: L[];
    onClick: (selectedAction: L, selectedRows: GridValidRowModel[]) => void;
  };
  props?: any;
  toolbarProps?: any;
}

export const ServerDataGrid = <L extends string>({
  rows,
  columns,
  rowCount,
  setTableStateParams,
  props,
  toolbarProps,
  checkboxActions,
}: Props<L>) => {
  const apiRef = useGridApiRef();
  const [pageSize, setPageSize] = useState(25);
  const [showCheckboxActions, setShowCheckboxActions] = useState(false);
  const handleQuickSearch = (e) =>
    apiRef.current.setQuickFilterValues([e.target.value]);

  if (checkboxActions) {
    const { onClick } = checkboxActions;
    checkboxActions.onClick = (selectedAction) => {
      const rows: GridValidRowModel[] = [];
      apiRef.current.getSelectedRows().forEach((row) => rows.push(row));
      onClick(selectedAction, rows);
    };
  }
  const _columns = columns.map((col) =>
    col.field.includes(".") && !col.valueGetter
      ? {
          ...col,
          valueGetter: (r) =>
            col.field.split(".").reduce((acc, cur) => {
              if (acc) return acc[cur];
            }, r.row),
        }
      : col,
  );
  return (
    <DataGridPro
      autoHeight
      apiRef={apiRef}
      rowCount={rowCount}
      rows={rows}
      columns={_columns}
      pagination
      rowsPerPageOptions={[10, 25, 50, 100, 250, 500]}
      pageSize={pageSize}
      disableSelectionOnClick
      onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
      sortingMode="server"
      filterMode="server"
      paginationMode="server"
      onStateChange={(s, _e, _d) => {
        setTableStateParams(buildParams(s));
      }}
      checkboxSelection={!!checkboxActions}
      components={{
        Toolbar: ToolBar,
      }}
      componentsProps={{
        toolbar: {
          handleQuickSearch: handleQuickSearch,
          checkboxActions: showCheckboxActions ? checkboxActions : undefined,
          ...toolbarProps,
        },
        baseCheckbox: {
          onClick: (e) => {
            setShowCheckboxActions(
              e.target.checked ||
                (apiRef.current.getSelectedRows().size > 1 &&
                  e.target.ariaLabel !== "Unselect all rows"),
            );
          },
        },
      }}
      {...props}
    />
  );
};
