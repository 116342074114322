import { Typography, Stack, IconButton, Paper, Grow } from "@mui/material";
import { Rating } from "react-simple-star-rating";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { useState } from "react";

interface StarRatingsProps<T extends "mini" | "full"> {
  rating: number;
  totalRatings: number;
  allowFraction?: boolean;
  type: T;
  stats?: T extends "full"
    ? {
        5: number;
        4: number;
        3: number;
        2: number;
        1: number;
      }
    : undefined;
}

const getDisplayRating = (totalRatings: number) =>
  totalRatings < 1000
    ? totalRatings
    : totalRatings < 100000
    ? `${Math.round(totalRatings / 100) / 10}K`
    : totalRatings < 1000000
    ? `${Math.round(totalRatings / 1000)}K`
    : totalRatings < 100000000
    ? `${Math.round(totalRatings / 100000) / 10}M`
    : `${Math.round(totalRatings / 1000000)}M`;

function RatingDisplay({
  rating,
  totalRatings,
  allowFraction,
  type,
  totalOutOf,
}: {
  rating: number;
  totalRatings: number;
  allowFraction?: boolean;
  type: "full" | "mini" | "stats";
  totalOutOf?: number;
}) {
  const base = type === "full" ? 1.33 : 1;
  const fontSize = 12 * base;
  const cutRating = getDisplayRating(totalRatings);
  const displayRating = totalOutOf ? cutRating + " / " + totalOutOf : cutRating;
  return (
    <Stack
      direction="row"
      alignItems="center"
      justifyContent="space-between"
      spacing={1 * base}
      width={type === "stats" ? "100%" : undefined}
    >
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="flex-end"
        spacing={0.8 * base}
      >
        {type !== "mini" && (
          <Typography fontWeight={600 * base} fontSize={fontSize}>
            {allowFraction ? Math.round(rating * 10) / 10 : Math.round(rating)}
          </Typography>
        )}
        <Rating
          initialValue={allowFraction ? rating : Math.round(rating)}
          readonly
          allowFraction={allowFraction}
          size={type === "full" ? 30 : 20}
          style={{ bottom: -1.5 }}
        />
      </Stack>
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="flex-end"
        spacing={0.4 * base}
      >
        <Typography
          fontWeight={500 * base}
          fontSize={fontSize}
          color="black"
          whiteSpace="nowrap"
          align="right"
        >
          {displayRating}
        </Typography>
        <Typography
          fontWeight={500}
          fontSize={fontSize}
          color="grey"
          whiteSpace="nowrap"
          align="right"
        >
          Ratings
        </Typography>
      </Stack>
    </Stack>
  );
}

function RatingsStatsDisplay({
  ratingsStats,
  total,
  sx,
}: {
  ratingsStats: Record<number, number>;
  total: number;
  sx?: any;
}) {
  const ratingOptions = [5, 4, 3, 2, 1];
  return (
    <Stack
      direction="column"
      justifyContent="flex-end"
      alignItems="flex-end"
      spacing={0}
      sx={sx}
      margin={0.8}
    >
      {ratingOptions.map((rating) => (
        <RatingDisplay
          rating={rating}
          totalRatings={ratingsStats[rating]}
          key={rating}
          type="stats"
          totalOutOf={total}
        />
      ))}
    </Stack>
  );
}

export function StarRatings<T extends "mini" | "full">({
  rating,
  totalRatings,
  type,
  allowFraction,
  stats,
}: StarRatingsProps<T>) {
  const [open, setOpen] = useState(false);

  const MainRating = (
    <RatingDisplay
      rating={rating}
      totalRatings={totalRatings}
      type={type}
      key={0}
      allowFraction={allowFraction}
    />
  );

  return type === "mini" ? (
    MainRating
  ) : (
    <Stack direction="row" alignItems="flex-start">
      <Stack alignItems="flex-end" marginTop={0.4}>
        <Stack direction="row">{MainRating}</Stack>
        <Grow in={open && type === "full" && !!stats}>
          <Paper sx={{ position: "absolute", zIndex: 1, top: "95.5%" }}>
            <RatingsStatsDisplay ratingsStats={stats!} total={totalRatings} />
          </Paper>
        </Grow>
      </Stack>
      <IconButton onClick={() => setOpen(!open)}>
        {open ? (
          <KeyboardArrowDownIcon
            sx={{
              fill: "grey",
            }}
          />
        ) : (
          <KeyboardArrowLeftIcon
            sx={{
              fill: "grey",
            }}
          />
        )}
      </IconButton>
    </Stack>
  );
}
