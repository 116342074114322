import { GridColDef, GridRenderCellParams } from "@mui/x-data-grid-pro";
import { stripHtml } from "string-strip-html";
import { formatDate } from "../../lib";
import { Link } from "react-router-dom";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import Modal from "../../components/modal/Modal";
import AdminPages from "../../pages/admin";
import {
  Discussion,
  Org,
  adminActions,
  adminSelectors,
  useSelector,
} from "../../state";
import { ConfirmDeleteDialog } from "./ConfirmDeleteDialog";
import { useState } from "react";
import { useDispatch } from "../../state";
import { EditIcon } from "../icons";
import { DropDown } from "../inputs/DropDown";

const getHeaderName = (field: string) => {
  return field
    .split("_")
    .map((s) => s[0].toUpperCase() + s.substring(1))
    .join(" ");
};

const fields = {
  string(field: string, width = 200, headerName = getHeaderName(field)) {
    return {
      field,
      headerName,
      type: "string",
      width,
    };
  },
  number(
    field: string,
    width = 100,
    headerName = getHeaderName(field),
  ): GridColDef {
    return {
      field,
      headerName,
      type: "number",
      align: "left",
      headerAlign: "left",
      width,
    };
  },
  date(field: string, headerName = getHeaderName(field)) {
    return {
      field,
      headerName,
      width: 125,
      valueFormatter: (v) => formatDate(v.value),
      type: "date",
    };
  },
  dateTime(field: string, headerName = getHeaderName(field)) {
    return {
      field,
      headerName,
      width: 200,
      valueFormatter: (v) => formatDate(v.value, "MM/dd/yyyy hh:mm a"),
      type: "date",
    };
  },
  boolean(field: string, headerName = getHeaderName(field)) {
    return {
      field,
      headerName,
      type: "boolean",
      width: 75,
    };
  },
};
const indexes: Record<string, number> = {};
let i = 1;
//     [Sequelize.col("events.uri"), "id"],
//     [Sequelize.col("event_tasks.task.id"), "task_id"],
//     [Sequelize.col("event_tasks.task.name"), "task_name"],
//     [Sequelize.col("invitees.awaiting_add_to_paymo"), "not_in_paymo"],
//     [Sequelize.col("event_tasks.task.project.id"), "project_id"],
//     [Sequelize.col("event_tasks.task.project.name"), "project_name"],
//     [Sequelize.col("event_tasks.task.project.client.name"), "client_name"],
//     [Sequelize.col("invitees.email"), "guest"],
//     [Sequelize.col("calendly_users.email"), "owner"],
export const EVENTS_COLUMNS: GridColDef[] = [
  {
    field: "id",
    headerName: "ID",
    width: 50,
    valueGetter(r) {
      if (!indexes[r.row.id]) indexes[r.row.id] = i++;
      return indexes[r.row.id];
    },
  },
  {
    ...fields.string("calendly_users.email", 150, "Owner"),
    valueGetter: (r) => r.row.calendly_users[0].email,
  },
  {
    ...fields.string("invitees.email", 150, "Guest"),
    valueGetter: (r) => r.row.invitees[0]?.email,
  },
  fields.string("name"),
  {
    ...fields.string("event_tasks.task.project.name", 150, "Project"),
    valueGetter: (r) => r.row.event_tasks[0]?.task?.project?.name,
  },
  fields.dateTime("start_time"),
  {
    field: "status",
    headerName: "Status",
    type: "singleSelect",
    width: 100,
    valueOptions: ["active", "canceled", "neither"],
  },
  fields.date("created_at"),
  {
    ...fields.boolean("invitees.awaiting_add_to_paymo", "Not In Paymo"),
    valueGetter: (r) => r.row.invitees[0]?.awaiting_add_to_paymo,
  },
];

export const ORGS_COLUMNS: GridColDef[] = [
  fields.number("id", 50, "ID"),
  fields.string("name", 350),
  fields.string("subdomain", 400),
  {
    field: "actions",
    type: "actions",
    headerName: "Actions",
    renderCell: (params) => {
      const { subdomain } = params.row;
      const [open, setOpen] = useState(false);
      const [input, setInput] = useState(subdomain);
      const dispatch = useDispatch();
      return (
        <>
          <Stack direction="row">
            <IconButton onClick={() => setOpen(true)}>
              <EditIcon />
            </IconButton>
          </Stack>
          <Dialog open={open}>
            <DialogTitle alignSelf="center">
              {(subdomain ? "Edit" : "Add") + " subdomain"}
            </DialogTitle>
            <DialogContent>
              <Box width={450}>
                <TextField
                  onChange={(e) => setInput(e.target.value)}
                  value={input}
                  fullWidth
                ></TextField>
              </Box>
            </DialogContent>
            <DialogActions>
              <Button
                onClick={() => {
                  setOpen(false);
                }}
                autoFocus
              >
                Cancel
              </Button>
              <Button
                disabled={!input || input === subdomain}
                onClick={() => {
                  dispatch(
                    adminActions.updateOrg(params.id as number, {
                      subdomain: input,
                    }),
                  );
                  setOpen(false);
                }}
              >
                Save
              </Button>
            </DialogActions>
          </Dialog>
        </>
      );
    },
  },
];

export const NFC_UUIDS_COLUMNS: GridColDef[] = [
  fields.number("id", 50, "ID"),
  fields.string("uuid"),
  fields.string("org_name", undefined, "Org"),
  {
    field: "actions",
    type: "actions",
    headerName: "Actions",
    renderCell: (params) => {
      const [open, setOpen] = useState(false);
      const [input, setInput] = useState(params.row.uuid);
      const { rows: orgs } = useSelector(adminSelectors.orgs);
      const [value, setValue] = useState<Org | undefined>({
        id: params.row.org_id,
        name: params.row.org_name,
      });
      const dispatch = useDispatch();
      return (
        <>
          <Stack direction="row">
            <IconButton onClick={() => setOpen(true)}>
              <EditIcon />
            </IconButton>
            <ConfirmDeleteDialog
              handleConfirm={() =>
                dispatch(adminActions.deleteUuid(params.row.id))
              }
            />
          </Stack>
          <Modal open={open} onClose={() => setOpen(false)} sx={{ width: 518 }}>
            <Stack width={450} spacing={1.5} alignItems="center">
              <Typography marginBottom={2} color="primary">
                Edit Uuid
              </Typography>
              <TextField
                onChange={(e) => setInput(e.target.value)}
                fullWidth
                value={input}
                placeholder="UUID"
              />
              <DropDown
                options={orgs}
                value={value}
                onValueChange={setValue}
                label="Orgs"
              />
              <Button
                color="primary"
                variant="contained"
                type="submit"
                disabled={!input || !value}
                onClick={() => {
                  setOpen(false);
                  setInput("");
                  setValue(undefined);
                  dispatch(
                    adminActions.updateUuid(params.row.id, {
                      org_id: value!.id,
                      uuid: input,
                    }),
                  );
                }}
              >
                Submit
              </Button>
            </Stack>
          </Modal>
        </>
      );
    },
  },
];

export const NOTES_COLUMNS: GridColDef[] = [
  {
    field: "id",
    headerName: "id",
    type: "number",
    align: "left",
    headerAlign: "left",
    width: 100,
  },
  {
    field: "external_legacy_ticket_id",
    headerName: "Ticket Id",
    type: "string",
    width: 120,
  },
  { field: "title", headerName: "Title", type: "string", width: 200 },
  {
    field: "created_by_resource",
    headerName: "Created By",
    type: "string",
    width: 150,
    valueGetter: (params) => {
      if (params.row.created_by_resource) {
        return params.row.created_by_resource + " (internal)";
      } else {
        return params.row.created_by_contact + " (external)";
      }
    },
  },
  {
    field: "summary_notes",
    headerName: "Note",
    type: "string",
    width: 300,
    valueGetter: (params) => {
      if (params.row.summary_note) {
        return params.row.summary_notes;
      } else {
        return params.row.internal_notes;
      }
    },
  },
  {
    field: "note_type_required_for_notes",
    headerName: "Note Type",
    type: "string",
    width: 100,
  },
];

export const SLACK_FORWARDINGS_COLUMNS = (dispatch): GridColDef[] => {
  return [
    {
      field: "id",
      headerName: "id",
      type: "number",
      align: "left",
      headerAlign: "left",
      width: 100,
    },
    {
      field: "from_name",
      headerName: "Forward From",
      type: "string",
      width: 250,
    },
    { field: "to_name", headerName: "Forward To", type: "string", width: 250 },
    {
      field: "start_time",
      headerName: "Start",
      width: 250,
      valueFormatter: (v) => formatDate(v.value, "MM/dd/yyyy hh:mm a"),
      type: "date",
    },
    {
      field: "end_time",
      headerName: "End",
      width: 250,
      valueFormatter: (v) => formatDate(v.value, "MM/dd/yyyy hh:mm a"),
      type: "date",
    },
    {
      field: "actions",
      headerName: "Actions",
      type: "actions",
      width: 75,
      renderCell: (params: GridRenderCellParams<number>) => (
        <ConfirmDeleteDialog
          handleConfirm={() =>
            dispatch(adminActions.deleteForward(params.id.toString()))
          }
        />
      ),
    },
  ];
};

export const DEVICES_COLUMNS: GridColDef[] = [
  { field: "id", headerName: "Id", type: "string", width: 75 },
  {
    field: "serial_number",
    headerName: "Serial Number",
    type: "string",
    width: 400,
  },
  { field: "org_name", headerName: "Org", type: "string", width: 400 },
  fields.string("imei", 200),
  fields.dateTime("last_contacted"),
];

export const TICKETS_COLUMNS: GridColDef[] = [
  {
    field: "id",
    headerName: "id",
    type: "string",
    width: 120,
  },
  {
    field: "required_title",
    headerName: "Title",
    type: "string",
    width: 200,
  },
  {
    field: "description",
    headerName: "Description",
    type: "string",
    width: 300,
  },
  {
    field: "required_company",
    headerName: "Company",
    type: "string",
    width: 125,
  },
  {
    field: "contact",
    headerName: "Contact",
    type: "string",
    width: 125,
  },
  {
    field: "required_status",
    headerName: "Status",
    type: "string",
    width: 125,
  },
  {
    field: "required_priority",
    headerName: "Priority",
    type: "string",
    width: 100,
  },
  {
    field: "required_source",
    headerName: "Source",
    type: "string",
    width: 100,
  },
  {
    field: "required_if_no_queue_primary_resource",
    headerName: "Primary Resource",
    type: "string",
    width: 125,
  },
  {
    field: "required_if_no_primary_resource_queue",
    headerName: "Queue",
    type: "string",
    width: 125,
  },
  {
    field: "required_ticket_type",
    headerName: "Ticket Type",
    type: "string",
    width: 125,
  },
  {
    field: "ticket_category",
    headerName: "Category",
    type: "string",
    width: 125,
  },
  {
    field: "work_type",
    headerName: "Work Type",
    type: "string",
    width: 125,
  },
  {
    field: "contract_name",
    headerName: "Contract",
    type: "string",
    width: 125,
  },
  {
    field: "service_level_agreement",
    headerName: "SLA",
    type: "string",
    width: 125,
  },
  {
    field: "create_date_time",
    headerName: "Created On",
    type: "string",
    width: 125,
  },
  {
    field: "created_by_resource",
    headerName: "Created By",
    type: "string",
    width: 125,
  },
  {
    field: "complete_date_time",
    headerName: "Completed On",
    type: "string",
    width: 125,
  },
  {
    field: "required_due_date_time",
    headerName: "Due Date",
    type: "string",
    width: 125,
  },
  {
    field: "resolution",
    headerName: "Resolution",
    type: "string",
    width: 125,
  },
  {
    field: "completed_by",
    headerName: "Completed By",
    type: "string",
    width: 125,
  },
];

export const PROJECT_SESSIONS_COLUMNS: GridColDef[] = [
  {
    field: "id",
    headerName: "STRING",
    type: "string",
    width: 1000,
    valueGetter: (v) => JSON.stringify(v.row),
  },
];

export const PROJECTS_COLUMNS: GridColDef[] = [
  {
    field: "id",
    headerName: "Id",
    type: "number",
    align: "left",
    headerAlign: "left",
    width: 100,
  },
  {
    field: "name",
    headerName: "Name",
    type: "string",
    width: 225,
    renderCell: (v) => (
      <a
        href={`https://app.paymoapp.com/#Paymo.Projects/${v.row.id}/tasks/complex`}
      >
        {v.value}
      </a>
    ),
  },
  {
    field: "client_name",
    headerName: "Client",
    type: "string",
    width: 200,
    renderCell: (v) => (
      <a
        href={`https://app.paymoapp.com/#Paymo.Clients/${v.row.client_id}/overview`}
      >
        {v.value}
      </a>
    ),
  },
  {
    field: "active",
    headerName: "Active",
    type: "boolean",
    width: 75,
  },
  {
    field: "budget_hours",
    headerName: "Budget Hrs",
    type: "number",
    align: "left",
    headerAlign: "left",
    width: 100,
  },
  {
    field: "hours_used",
    headerName: "Hours Used",
    type: "number",
    align: "left",
    headerAlign: "left",
    width: 100,
  },
  {
    field: "billable",
    headerName: "Billable",
    type: "boolean",
    width: 75,
  },
  {
    field: "created_on",
    headerName: "Created On",
    width: 125,
    valueFormatter: (v) => formatDate(v.value),
    type: "date",
  },
  {
    field: "updated_on",
    headerName: "Updated On",
    width: 125,
    valueFormatter: (v) => formatDate(v.value),
    type: "date",
  },
  {
    field: "description",
    headerName: "Description",
    type: "string",
    width: 400,
    valueGetter: (v) => stripHtml(v.value || "").result,
  },
];

export const DISCUSSIONS_COLUMNS: GridColDef[] = [
  {
    field: "id",
    headerName: "Id",
    type: "number",
    align: "left",
    headerAlign: "left",
    width: 150,
  },
  {
    field: "name",
    headerName: "Name",
    type: "string",
    width: 300,
    renderCell: (params: GridRenderCellParams<string, Discussion>) => (
      <>
        <Link
          to={AdminPages.discussion.path.replace(
            ":id",
            params.row.id.toString(),
          )}
        >
          <Typography>{params.value}</Typography>
        </Link>
      </>
    ),
  },
  {
    field: "project_name",
    headerName: "Project",
    type: "string",
    width: 300,
    renderCell: (params: GridRenderCellParams<string, Discussion>) => (
      <>
        <Link
          to={`https://app.paymoapp.com/#Paymo.Projects/${params.row.project_id}/tasks/complex`}
        >
          <Typography>{params.value}</Typography>
        </Link>
      </>
    ),
  },
  {
    field: "user_name",
    headerName: "Created By",
    type: "string",
    width: 250,
  },
  {
    field: "created_on",
    headerName: "Created On",
    width: 200,
    valueFormatter: (v) => formatDate(v.value),
    type: "date",
  },
  {
    field: "description",
    headerName: "Description",
    type: "string",
    width: 1000,
  },
];

export const SESSION_DETAILS_COLUMNS: GridColDef[] = [
  {
    field: "id",
    headerName: "Id",
    type: "number",
    align: "left",
    headerAlign: "left",
    width: 100,
  },
  {
    field: "name",
    headerName: "Task",
    type: "string",
    width: 225,
  },
];

export const SESSIONS_DETAILS_COLUMNS: GridColDef[] = [
  {
    field: "id",
    headerName: "Id",
    type: "number",
    align: "left",
    headerAlign: "left",
    width: 100,
  },
  {
    field: "client_name",
    headerName: "Client",
    type: "string",
    width: 200,
    renderCell: (v) => (
      <a
        href={`https://app.paymoapp.com/#Paymo.Clients/${v.row.client_id}/overview`}
      >
        {v.value}
      </a>
    ),
  },
  {
    field: "id",
    headerName: "Sessions Link",
    type: "string",
    width: 225,
    renderCell: (params: GridRenderCellParams<number>) => (
      <>
        <Link
          to={AdminPages.sessionDetails.path.replace(
            ":id",
            params.id.toString(),
          )}
        >
          <Typography>{params.row.name}</Typography>
        </Link>
      </>
    ),
  },
  {
    field: "name",
    headerName: "Project",
    type: "string",
    width: 225,
    renderCell: (v) => (
      <a
        href={`https://app.paymoapp.com/#Paymo.Projects/${v.row.id}/tasks/complex`}
      >
        {v.value}
      </a>
    ),
  },
  {
    field: "budget_hours",
    headerName: "Budget Hrs",
    type: "number",
    align: "left",
    headerAlign: "left",
    width: 100,
  },
  {
    field: "hours_used",
    headerName: "Hours Used",
    type: "number",
    align: "left",
    headerAlign: "left",
    width: 100,
  },
  {
    field: "consultant",
    headerName: "Consultant",
    type: "string",
    width: 150,
    valueGetter: (r) => r.value?.name,
  },
];

export const TASKS_COLUMNS: GridColDef[] = [
  {
    field: "id",
    headerName: "Id",
    type: "number",
    align: "left",
    headerAlign: "left",
    width: 100,
  },
  {
    field: "name",
    headerName: "Name",
    type: "string",
    width: 225,
    renderCell: (v) => (
      <a
        href={`https://app.paymoapp.com/#Paymo.Projects/${v.row.project_id}/tasks/complex/task/${v.row.id}`}
      >
        {v.value}
      </a>
    ),
  },
  {
    field: "project.name",
    headerName: "Project",
    type: "string",
    width: 200,
    valueGetter: (p) => p.row.project.name,
    renderCell: (v) => (
      <a
        href={`https://app.paymoapp.com/#Paymo.Projects/${v.row.project_id}/tasks/complex`}
      >
        {v.value}
      </a>
    ),
  },
  {
    field: "created_by.name",
    headerName: "User",
    valueGetter: (p) => p.row.created_by?.name,
    type: "string",
    width: 200,
  },
  {
    field: "auto_generated",
    headerName: "Auto Generated",
    type: "boolean",
    width: 75,
  },
  {
    field: "visible_for_guests",
    headerName: "Visible For Guests",
    type: "boolean",
    width: 75,
  },
  {
    field: "complete",
    headerName: "Complete",
    type: "boolean",
    width: 75,
  },
  {
    field: "project.client.name",
    headerName: "Client",
    type: "string",
    valueGetter: (p) => p.row.project.client.name,
  },
  {
    field: "threads.comments.content",
    headerName: "Threads",
    filterable: false,
    sortable: false,
    type: "string",
    valueGetter: (p) =>
      p.row.threads
        .map((t) => t.comments?.map((c) => c?.content).join(", "))
        .join(", "),
  },
  {
    field: "created_on",
    headerName: "Created On",
    width: 125,
    //valueFormatter: (v) => formatDate(v.value),
    type: "dateTime",
  },
  {
    field: "updated_on",
    headerName: "Updated On",
    width: 125,
    valueFormatter: (v) => formatDate(v.value),
    type: "date",
  },
  {
    field: "description",
    headerName: "Description",
    type: "string",
    width: 400,
    valueGetter: (v) => stripHtml(v.value || "").result,
  },
];

export const PAYMO_USERS_COLUMNS: GridColDef[] = [
  {
    field: "id",
    headerName: "Id",
    type: "number",
    align: "left",
    headerAlign: "left",
    width: 100,
  },
  {
    field: "name",
    headerName: "Name",
    type: "string",
    width: 225,
    renderCell: (v) => (
      <a href={`https://app.paymoapp.com/#Paymo.Users/${v.row.id}/overview`}>
        {v.value}
      </a>
    ),
  },
  {
    field: "type",
    headerName: "Type",
    type: "string",
    width: 125,
  },
  {
    field: "position",
    headerName: "Position",
    type: "string",
    width: 125,
  },
  {
    field: "email",
    headerName: "Email",
    width: 125,
    type: "string",
  },
  {
    field: "phone",
    headerName: "Phone",
    width: 125,
    type: "string",
  },
  {
    field: "created_on",
    headerName: "Created On",
    width: 125,
    valueFormatter: (v) => formatDate(v.value),
    type: "date",
  },
  {
    field: "updated_on",
    headerName: "Updated On",
    width: 125,
    valueFormatter: (v) => formatDate(v.value),
    type: "date",
  },
];

export const CLIENTS_COLUMNS: GridColDef[] = [
  {
    field: "id",
    headerName: "Id",
    type: "number",
    align: "left",
    headerAlign: "left",
    width: 100,
  },
  {
    field: "name",
    headerName: "Name",
    type: "string",
    width: 225,
    renderCell: (v) => (
      <a href={`https://app.paymoapp.com/#Paymo.Clients/${v.row.id}/overview`}>
        {v.value}
      </a>
    ),
  },
  {
    field: "email",
    headerName: "Email",
    width: 175,
    type: "string",
  },
  {
    field: "phone",
    headerName: "Phone",
    width: 125,
    type: "string",
  },
  {
    field: "address",
    headerName: "Address",
    width: 175,
    type: "string",
  },
  {
    field: "city",
    headerName: "City",
    width: 110,
    type: "string",
  },
  {
    field: "state",
    headerName: "State",
    width: 75,
    type: "string",
  },
  {
    field: "country",
    headerName: "Country",
    width: 90,
    type: "string",
  },
  {
    field: "postal_code",
    headerName: "Postal Code",
    type: "string",
    width: 100,
  },
  {
    field: "created_on",
    headerName: "Created On",
    width: 125,
    valueFormatter: (v) => formatDate(v.value),
    type: "date",
  },
  {
    field: "updated_on",
    headerName: "Updated On",
    width: 125,
    valueFormatter: (v) => formatDate(v.value),
    type: "date",
  },
];
