import { adminActions, adminSelectors } from "../../state";
import { TASKS_COLUMNS } from "../../components/tables/columns";
import GenericSSPPage from "../../components/tables/GenericServerSidePage";

export default function TasksPage() {
  return (
    <GenericSSPPage
      adminAction={adminActions.getTasks}
      rowsSelector={adminSelectors.tasks}
      columns={TASKS_COLUMNS}
      dataDisplayName="Tasks"
    />
  );
}
