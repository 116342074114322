import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { adminActions, adminSelectors } from "../../state";
import {
  Autocomplete,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  //MenuItem,
  //Select,
  TextField,
} from "@mui/material";
//import { AddIcon } from "../../components";
import //ActionBox,
"./GenericPage.styles";
import { DEVICES_COLUMNS } from "../../components/tables/columns";
//import { LinkOrgDevicesForm } from "../../components/forms/LinkOrgDevicesForm";
//import Modal from "../../components/modal/Modal";
import GenericSSPPage from "../../components/tables/GenericServerSidePage";
import { GridValidRowModel } from "@mui/x-data-grid";
import { useSelector } from "react-redux";

export const DevicesPage = () => {
  const dispatch = useDispatch();
  //const [openModal, setOpenModal] = useState(false);
  const [selectedRows, setSelectedRows] = useState<GridValidRowModel[]>([]);
  const { rows: orgs } = useSelector(adminSelectors.orgs);
  const [selectedOrg, setSelectedOrg] = useState({ id: 0, name: "" });
  const [inputValue, setInputValue] = useState("");

  const [dialogToOpen, setDialogToOpen] = useState("");

  useEffect(() => {
    dispatch(adminActions.getOrgs());
  }, [dispatch]);

  return (
    <>
      {/* <ActionBox>
        <Button
          fullWidth
          variant="outlined"
          color="primary"
          onClick={() => {
            setOpenModal(true);
          }}
        >
          <AddIcon /> Link Devices
        </Button>
      </ActionBox> */}
      <GenericSSPPage
        adminAction={adminActions.getDevices}
        rowsSelector={adminSelectors.devices}
        columns={DEVICES_COLUMNS}
        dataDisplayName="Devices"
        checkboxActions={{
          labels: ["Link To Org", "Delete"],
          onClick: (selectedAction, selectedRows) => {
            setDialogToOpen(selectedAction);
            setSelectedRows(selectedRows);
          },
        }}
      />
      {/* <Modal
        open={openModal}
        onClose={() => {
          setOpenModal(!openModal);
        }}
        sx={{ width: "75%", height: "35%" }}
      >
        <LinkOrgDevicesForm
          closeModal={() => {
            setOpenModal(!openModal);
          }}
        />
      </Modal> */}
      <Dialog open={!!dialogToOpen}>
        <DialogTitle>{dialogToOpen}</DialogTitle>
        <DialogContent>
          {dialogToOpen === "Delete" ? (
            <DialogContentText>
              Are you sure you want to delete this?
            </DialogContentText>
          ) : dialogToOpen === "Link To Org" ? (
            <Autocomplete
              options={orgs}
              getOptionLabel={(option) => option.name}
              renderInput={(params) => <TextField {...params} label="Org" />}
              renderOption={(props, option) => (
                <Box component="li" {...props} key={option.id}>
                  {option.name}
                </Box>
              )}
              value={selectedOrg}
              inputValue={inputValue}
              onChange={(event: any, newValue: any, reason) => {
                setSelectedOrg(newValue);
                let _newValue = newValue;
                if (reason === "clear") {
                  _newValue = {
                    id: 0,
                    name: "",
                  };
                }
              }}
              onInputChange={(event, newInputValue) => {
                setInputValue(newInputValue);
              }}
              sx={{ width: 450 }}
            />
          ) : undefined}
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setDialogToOpen("");
            }}
            autoFocus
          >
            Cancel
          </Button>
          <Button
            disabled={!selectedOrg?.id}
            onClick={() => {
              dispatch(
                adminActions.linkDevicesToOrgs({
                  org_id: selectedOrg.id,
                  devices: selectedRows.map((d) => d.id),
                }),
              );
              setDialogToOpen("");
            }}
          >
            {dialogToOpen}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
