import { useEffect, useState } from "react";
import {
  useDispatch,
  adminActions,
  useSelector,
  adminSelectors,
  uiActions,
  Project,
} from "../../state";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import { EVENTS_COLUMNS } from "../../components/tables/columns";
import { DropDown } from "../../components/inputs/DropDown";
import GenericSSPPage from "../../components/tables/GenericServerSidePage";

export default function EventsPage() {
  const dispatch = useDispatch();
  const projects = useSelector(adminSelectors.projects);
  const [dialogToOpen, setDialogToOpen] = useState(false);
  const [selectedProject, setSelectedProject] = useState<Project>();
  const [selectedRows, setSelectedRows] = useState<any>();

  useEffect(() => {
    dispatch(adminActions.getCsProjects());
  }, [dispatch]);

  return (
    <>
      <GenericSSPPage
        adminAction={adminActions.getEvents}
        rowsSelector={adminSelectors.events}
        dataDisplayName="Events"
        columns={EVENTS_COLUMNS}
        checkboxActions={{
          labels: ["Create Tasklist"],
          onClick: (selectedAction, selectedRows) => {
            setSelectedRows(selectedRows);
            const badEvent = selectedRows.find(
              (sr) => sr.task_id || sr.status === "canceled",
            );
            if (badEvent) {
              if (badEvent.status === "canceled") {
                dispatch(
                  uiActions.showError(
                    "Can not create tasklist for canceled event",
                  ),
                );
              } else {
                dispatch(
                  uiActions.showError(
                    "Can not create tasklist for an event that already has one",
                  ),
                );
              }
            } else {
              setDialogToOpen(true);
            }
          },
        }}
      />
      <Dialog open={dialogToOpen}>
        <DialogTitle>{"Create Tasklist"}</DialogTitle>
        <DialogContent>
          <DropDown
            options={projects.rows}
            value={selectedProject}
            onValueChange={setSelectedProject}
            label="Project"
          />
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setDialogToOpen(false);
            }}
            autoFocus
          >
            Cancel
          </Button>
          <Button
            disabled={!selectedProject}
            onClick={() => {
              dispatch(
                adminActions.createEventTasks({
                  project_id: selectedProject!.id,
                  events: selectedRows.map((d) => d.uri),
                }),
              );
              setDialogToOpen(false);
            }}
          >
            {"Create Tasklist"}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
