import UserPage from "./UserPage";
import { DashboardPage } from "./DashboardPage";
import { UsersPage } from "./UsersPage";
import ProfilePage from "./ProfilePage";
import { RolesPage } from "./RolesPage";
import { MediaPage } from "./MediaPage";
import ProjectsPage from "./ProjectsPage";
import TasksPage from "./TasksPage";
import Chatbot from "./Chatbot";
import PaymoUsersPage from "./PaymoUsersPage";
import ClientsPage from "./ClientsPage";
import SessionDetailsPage from "./SessionDetailsPage";
import SessionsDetailsPage from "./SessionsDetailsPage";
import TicketsPage from "./TicketsPage";
import SearchTicketsPage from "./SearchTicketsPage";
import ProjectSessionPage from "./ProjectPage";
import { PaymoApiKeyUploadPage } from "./PaymoApiKeyUploadPage";
import DiscussionsPage from "./DiscussionsPage";
import { DiscussionCommentsPage } from "./DiscussionCommentsPage";
import SearchCommunityPage from "./SearchCommunityPage";
import { SearchAllPage } from "./SearchAll";
import { SlackForwardingPage } from "./SlackForwarding";
import { DevicesPage } from "./DevicesPage";
import EventsPage from "./EventsPage";
import OrgsPage from "./OrgsPage";
import NfcUuidsPage from "./NfcUuidsPage";

export const AdminPages = {
  chatbot: {
    anon: false,
    path: "/admin/chatbot",
    title: "Chatbot",
    view: Chatbot,
  },
  dashboard: {
    anon: false,
    path: "/admin",
    title: "Dashboard",
    view: DashboardPage,
  },
  users: {
    anon: false,
    path: "/admin/users",
    title: "Users",
    view: UsersPage,
  },
  createUsers: {
    anon: false,
    path: "/admin/users/create",
    title: "Create User",
    view: UserPage,
  },
  editUsers: {
    anon: false,
    path: "/admin/users/:id",
    title: "Edit User",
    view: UserPage,
  },
  media: {
    anon: false,
    path: "/admin/media",
    title: "Media",
    view: MediaPage,
  },
  roles: {
    anon: false,
    path: "/admin/roles",
    title: "Roles",
    view: RolesPage,
  },
  profile: {
    anon: false,
    path: "/admin/profile",
    title: "Edit Profile",
    view: ProfilePage,
  },
  projects: {
    anon: false,
    path: "/admin/projects",
    title: "Paymo Projects",
    view: ProjectsPage,
  },
  sessionsDetails: {
    anon: false,
    path: "/admin/project-sessions",
    title: "Paymo Sessions",
    view: SessionsDetailsPage,
  },
  sessionDetails: {
    anon: false,
    path: "/admin/project-sessions/:id",
    title: "Session details",
    view: SessionDetailsPage,
  },
  tasks: {
    anon: false,
    path: "/admin/tasks",
    title: "Paymo Tasks",
    view: TasksPage,
  },
  paymoUsers: {
    anon: false,
    path: "/admin/paymo-users",
    title: "Paymo Users",
    view: PaymoUsersPage,
  },
  clients: {
    anon: false,
    path: "/admin/clients",
    title: "Paymo Clients",
    view: ClientsPage,
  },
  orgs: {
    anon: false,
    path: "/admin/orgs",
    title: "Orgs",
    view: OrgsPage,
  },
  discussions: {
    anon: false,
    path: "/admin/discussions",
    title: "Paymo Discussions",
    view: DiscussionsPage,
  },
  discussion: {
    anon: false,
    path: "/admin/discussion/:id",
    title: "Discussion Comments",
    view: DiscussionCommentsPage,
  },
  tickets: {
    anon: false,
    path: "/admin/tickets",
    title: "Old Admire Tickets",
    view: TicketsPage,
  },
  searchTickets: {
    anon: false,
    path: "/admin/search-tickets",
    title: "Search Old Admire Tickets",
    view: SearchTicketsPage,
  },
  searchCommunity: {
    anon: false,
    path: "/admin/search-community",
    title: "Search Admire Community",
    view: SearchCommunityPage,
  },
  projectSessions: {
    anon: false,
    path: "/admin/projects/:project_id",
    title: "Project Sessions",
    view: ProjectSessionPage,
  },
  paymoApiKeyUpload: {
    anon: false,
    path: "/admin/paymo-api-key",
    title: "Upload Paymo Api Key",
    view: PaymoApiKeyUploadPage,
  },
  searchAllPage: {
    anon: false,
    path: "/admin/search-all",
    title: "Search All",
    view: SearchAllPage,
  },
  slackForwardingPage: {
    anon: false,
    path: "/admin/slack-forwarding",
    title: "Slack Forwarding",
    view: SlackForwardingPage,
  },
  orgsPage: {
    anon: false,
    path: "/admin/orgs",
    title: "Organizations",
    view: OrgsPage,
  },
  nfcUuidsPage: {
    anon: false,
    path: "/admin/nfc-uuids",
    title: "Nfc Uuids",
    view: NfcUuidsPage,
  },
  devicesPage: {
    anon: false,
    path: "/admin/devices",
    title: "Devices",
    view: DevicesPage,
  },
  eventsPage: {
    anon: false,
    path: "/admin/events",
    title: "Calendly Events",
    view: EventsPage,
  },
};

export default AdminPages;
