import axios, {
  AxiosInstance,
  AxiosRequestConfig,
  AxiosResponse,
  AxiosError,
  ParamsSerializerOptions,
} from "axios";
import qs from "query-string";
import { KEY_PREFIX } from "redux-persist";
import { REACT_APP_API_URL, REACT_APP_PERSIST_KEY } from "../../config";
import { auth, AuthState, Role } from "./state";
import { getQueryParam, Navigation } from "../../lib";
import { AppThunk, User } from "../types";
import { uiActions } from "../ui/actions";
import { store } from "../../state";

const { actions } = auth;

interface AuthResponse {
  user?: { id: number; roles: Role[]; email: string };
  token?: string;
  id?: number;
}

/** Client for making authenticated API calls. */
export const authClient = {
  delete(url: string, config?: AxiosRequestConfig) {
    return handleAuthResponse((apiClient) => apiClient.delete(url, config));
  },
  download(_url: string, _config?: AxiosRequestConfig) {
    return Promise.reject("TODO: Implement apiDownload.");
  },
  get<T = any>(url: string, config?: AxiosRequestConfig) {
    return handleAuthResponse((apiClient) => apiClient.get<T>(url, config));
  },
  post<T = any>(url: string, data: any, config?: AxiosRequestConfig) {
    return handleAuthResponse((apiClient) =>
      apiClient.post<T>(url, data, config),
    );
  },
  put<T = any>(url: string, data: any, config?: AxiosRequestConfig) {
    return handleAuthResponse((apiClient) =>
      apiClient.put<T>(url, data, config),
    );
  },
};

export const authActions = {
  ...actions,
  /** @param {any} [authResponseData] Response data to load. Optional. */
  load(authResponseData: AuthResponse): AppThunk {
    let authStateFromResponse: AuthState;
    if (authResponseData) {
      const { user, token } = authResponseData;
      if (user) {
        authStateFromResponse = {
          userId: user.id,
          roles: user.roles,
          token,
          userName: user.email,
        };
      }
    }
    return async (dispatch, getState) => {
      let authState: AuthState;
      if (authStateFromResponse) {
        authState = authStateFromResponse;
        dispatch(actions.setAuthState(authState));
      } else {
        authState = getState().auth;
      }
      createApiClient(authState);
    };
  },
  loadUserValues(user?: User, roles?: Role[]): AppThunk {
    return async (dispatch) => {
      if (user) {
        const authState: AuthState = {
          roles,
          user,
        };
        dispatch(actions.setAuthState(authState));
      }
    };
  },
  login(values: {
    email: string;
    password: string;
  }): AppThunk<Promise<boolean>> {
    return async (dispatch) => {
      dispatch(uiActions.setLoading(true));
      try {
        const {
          data: { user, token },
        } = await axios.post(`/auth/login`, values, {
          baseURL: REACT_APP_API_URL,
          headers: { "Content-Type": "application/json" },
        });
        Navigation.replace(getQueryParam("after") || "/admin");
        dispatch(uiActions.setLoading(false));
        const authState: AuthState = {
          userId: user.id,
          roles: user.roles,
          token,
          userName: user.email,
          user,
          requiresMfa: user.mfa_required,
        };
        createApiClient(authState);
        dispatch(actions.setAuthState(authState));
        return true;
      } catch (e: any) {
        dispatch(uiActions.setLoading(false));
        dispatch(uiActions.showError("Oops, something went wrong"));
        return false;
      }
    };
  },
  socialLogin(values: {
    credential: string;
    sign_in_type: "google" | "microsoft";
  }): AppThunk<Promise<boolean>> {
    return async (dispatch) => {
      dispatch(uiActions.setLoading(true));
      try {
        const {
          data: { user, token },
        } = await axios.post(`/auth/social-login`, values, {
          baseURL: REACT_APP_API_URL,
          headers: { "Content-Type": "application/json" },
        });
        Navigation.replace(getQueryParam("after") || "/admin");
        dispatch(uiActions.setLoading(false));
        const authState: AuthState = {
          userId: user.id,
          roles: user.roles,
          token,
          userName: user.email,
          user,
        };
        createApiClient(authState);
        dispatch(actions.setAuthState(authState));
        return true;
      } catch (e) {
        dispatch(uiActions.showError());
        dispatch(uiActions.setLoading(false));
        return false;
      }
    };
  },
  logout(): AppThunk {
    return async (dispatch) => {
      await logout(dispatch);
    };
  },
  recoverPassword(values: { email: string }): AppThunk {
    return (_dispatch) => {
      return axios.post(`/auth/password/forgot`, values, {
        baseURL: REACT_APP_API_URL,
        headers: { "Content-Type": "application/json" },
      });
    };
  },
  multifactorAuth(mfaCode: string): AppThunk {
    return async (dispatch) => {
      //await Promise.resolve();
      const {
        data: { user, token, message },
      } = await authClient.post(
        `/auth/multifactor-auth`,
        { mfaCode },
        {
          baseURL: REACT_APP_API_URL,
          headers: { "Content-Type": "application/json" },
        },
      );
      if (message !== null && message !== undefined) {
        dispatch(uiActions.showError(message));
      }
      if (user.mfa_required === false) {
        Navigation.replace(getQueryParam("after") || "/admin");
        dispatch(uiActions.setLoading(false));
        const authState: AuthState = {
          userId: user.id,
          roles: user.roles,
          token,
          userName: user.email,
          user,
          requiresMfa: user.mfa_required,
        };
        createApiClient(authState);
        dispatch(actions.setAuthState(authState));
      }
    };
  },
  resendMfaCode(): AppThunk {
    return async (dispatch) => {
      await Promise.resolve();
      await authClient.get(`/auth/resend-mfa-code`, {
        baseURL: REACT_APP_API_URL,
        headers: { "Content-Type": "application/json" },
      });
      await logout(dispatch);
    };
  },
  resetPassword(values: { newPassword: string; token: string }): AppThunk {
    return async (dispatch) => {
      await Promise.resolve();
      try {
        await axios.put(`/auth/password/reset`, values, {
          baseURL: REACT_APP_API_URL,
          headers: { "Content-Type": "application/json" },
        });
        await logout(dispatch);
      } catch (e) {
        dispatch(uiActions.showError("Oops Something went wrong"));
      }
    };
  },
  confirmAccount(values: {
    email: string;
    newPassword: string;
    token: string;
  }): AppThunk {
    return async () => {
      await axios.put(`/auth/confirm-account`, values, {
        baseURL: REACT_APP_API_URL,
        headers: { "Content-Type": "application/json" },
      });
      // dispatch(actions.setAuthState(undefined));
    };
  },
};

/** Connection used to make authorized, authenticated API calls. */
export let apiClient: AxiosInstance;

function createApiClient(state: AuthState) {
  const config: AxiosRequestConfig = {
    baseURL: REACT_APP_API_URL,
    headers: {},
  };
  if (!config.headers) {
    throw new Error("Invalid Api Config");
  }
  if (state && state.token) {
    config.headers.Authorization = `Bearer ${state.token}`;
  }
  config.headers["Content-Type"] = "application/json";
  apiClient = axios.create(config);
}

export function timeoutAsync(ms = 0) {
  return new Promise((resolve, _reject) => {
    setTimeout(resolve, ms);
  });
}
let refreshWait = 100;
/**
 * @param promise
 */
async function handleAuthResponse<T = any>(
  promise: (_apiClient: AxiosInstance) => Promise<AxiosResponse<T>>,
  retrying = 0,
): Promise<AxiosResponse<T>> {
  let error: AxiosError<T> | undefined;
  const promiseWithCatch = promise(apiClient).catch((err) => {
    return err.response;
  });
  const res = await promiseWithCatch;
  if (error) {
    console.error({ error, res });
  }
  if (res.status && res.status === 401) {
    const dispatch: any = store.dispatch;
    if (!retrying) {
      refreshWait += refreshWait + 200;
      console.warn("Calling refresh token", refreshWait);
      if (refreshWait > 30000) {
        console.error("refreshWait too high");
        return res;
      }
      const response = await authClient.get("/auth/refresh-token");
      if (response.status === 200) {
        await dispatch(authActions.load(response.data));
        await timeoutAsync(refreshWait);
        return handleAuthResponse(promise, 1);
      } else {
        // if refresh token is also expired
        await logout(dispatch);
      }
    } else {
      await logout(dispatch);
      redirectToLogin();
    }
  }
  return res;
}

export function redirectToLogin() {
  window.location.replace(
    "/auth/login?after=" +
      encodeURIComponent(window.location.pathname + window.location.search),
  );
}

async function logout(dispatch) {
  // NOTE: We could do  window.localStorage.clear(); but other JS might be
  // using localStorage, so just remove the key that our Redux app saves.
  window.localStorage.removeItem(`${KEY_PREFIX}${REACT_APP_PERSIST_KEY}`);
  await timeoutAsync(500);
  await authClient.get("/auth/logout").catch(console.error);
  dispatch(actions.setAuthState(undefined));
  await timeoutAsync(500);
  redirectToLogin();
}

/**
 * Serializes URL params correctly for `express-openapi-validator`. See:
 * - https://github.com/axios/axios/issues/678#issuecomment-634632500
 * - https://github.com/axios/axios/blob/8a8c534a609cefb10824dec2f6a4b3ca1aa99171/lib/helpers/buildURL.js
 * - https://github.com/axios/axios/blob/59ab559386273a185be18857a12ab0305b753e50/lib/utils.js#L177
 *
 * @param params The query params.
 */
const serializeParams: ParamsSerializerOptions = {
  encode: (params: { [x: string]: any; toString?: any }) => {
    if (params instanceof URLSearchParams) {
      return params.toString();
    }
    const formattedParams = {};
    const keys = Object.keys(params);
    const { length } = keys;
    for (let i = 0; i < length; i++) {
      const key = keys[i];
      let value = params[key];
      if (value === null || value === undefined) {
        continue;
      }
      if (Object.prototype.toString.call(value) === "[object Date]") {
        // Format Dates...
        value = value.toISOString();
      } else if (value !== null && typeof value === "object") {
        // Format objects and arrays...
        value = JSON.stringify(value);
      }
      formattedParams[key] = value;
    }
    // URLSearchParams does not handle arrays...
    // return new URLSearchParams(formattedParams).toString();
    return qs.stringify(formattedParams);
  },
};
axios.defaults.paramsSerializer = serializeParams;

// #region Types

/** Return value for API call wrappers. */
export type ApiCall<T = any> = AppThunk<Promise<AxiosResponse<T>>>;

// #endregion
