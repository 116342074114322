import { AppState } from "../types";

export const adminSelectors = {
  requiresMfa: (state: AppState) => state.auth.requiresMfa,
  users(state: AppState) {
    return state.admin.users;
  },
  usersCount(state: AppState) {
    return state.admin.users.count;
  },
  media(state: AppState) {
    return state.admin.media;
  },
  mediaCount(state: AppState) {
    return state.admin.media.count;
  },
  projects(state: AppState) {
    return state.admin.projects;
  },
  projectsCount(state: AppState) {
    return state.admin.projects.count;
  },
  tasks(state: AppState) {
    return state.admin.tasks;
  },
  tasksCount(state: AppState) {
    return state.admin.tasks.count;
  },
  paymoUsers(state: AppState) {
    return state.admin.paymoUsers;
  },
  clients(state: AppState) {
    return state.admin.clients;
  },
  sessionTasks(state: AppState) {
    return state.admin.sessionTasks;
  },
  tickets(state: AppState) {
    return state.admin.tickets;
  },
  notes(state: AppState) {
    return state.admin.notes;
  },
  milvusRes(state: AppState) {
    return state.admin.milvusRes;
  },
  projectSessions(state: AppState) {
    return state.admin.projectSessions;
  },
  stats(state: AppState) {
    return state.admin.stats;
  },
  orgs(state: AppState) {
    return state.admin.orgs;
  },
  discussions(state: AppState) {
    return state.admin.discussions;
  },
  discussion(state: AppState) {
    return state.admin.discussion;
  },
  communityRes(state: AppState) {
    return state.admin.communityRes;
  },
  slackForwards(state: AppState) {
    return state.admin.slackForwards;
  },
  slackUsers(state: AppState) {
    return state.admin.slackUsers;
  },
  devices(state: AppState) {
    return state.admin.devices;
  },
  events(state: AppState) {
    return state.admin.events;
  },
  nfcUuids(state: AppState) {
    return state.admin.nfcUuids;
  },
};
