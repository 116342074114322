import { useEffect, useState } from "react";
import { REACT_APP_API_URL } from "../../config";
import { authClient } from "../../state";
import { deepClone } from "@mui/x-data-grid/utils/utils";
import { ChatMessage, ChatContainer } from "../../components/text/ChatMessages";
import { SxProps } from "@mui/material";

interface Message {
  role: "user" | "assistant" | "";
  content: string;
}

interface ChatResultsProps {
  search: string;
  hide?: boolean;
  sx?: SxProps;
}

const ChatResults = ({ search, hide, sx }: ChatResultsProps) => {
  const [messages, setMessages] = useState<Message[]>([]);
  const [responding, setResponding] = useState(false);
  const [chat_id, setChat_id] = useState(Promise.resolve(""));
  const getChatId = async () => {
    const { data } = await authClient.post("/new_chat", {});
    return data.chat_id;
  };

  useEffect(() => {
    setChat_id(getChatId());
  }, []);

  const sendMessage = async (input: string) => {
    setResponding(true);
    const order_num = messages.length;
    setMessages([
      ...messages,
      { role: "user", content: input },
      { role: "assistant", content: "" },
    ]);
    const baseUrl =
      process.env.NODE_ENV === "development" ? "http://localhost:10010" : "";
    const eventSource = new EventSource(
      baseUrl +
        REACT_APP_API_URL +
        "/admire_chatbot/" +
        (await chat_id) +
        "?content=" +
        input +
        "&order_num=" +
        order_num,
    );
    eventSource.onmessage = ({ data }) => {
      if (data.includes("[DONE]")) {
        eventSource.close();
        setResponding(false);
        return;
      }
      try {
        const {
          choices: [{ delta }],
        } = JSON.parse(data);
        setMessages((prevMessages) => {
          const _prevMessages = deepClone(prevMessages);
          _prevMessages[_prevMessages.length - 1].content +=
            delta?.content || "";
          return _prevMessages;
        });
      } catch (e) {
        console.error(e);
        setResponding(false);
      }
    };
    eventSource.onerror = (event) => {
      console.error("EventSource failed:", event);
      eventSource.close();
      setResponding(false);
    };
  };

  useEffect(() => {
    if (search) {
      sendMessage(search);
    }
  }, [search]);

  return (
    <ChatContainer sx={sx}>
      {!hide &&
        messages
          .filter(({ role, content }) => role && content)
          .map((_m, index, allMessages) => {
            const order_num = allMessages.length - index - 1;
            const { role, content } = allMessages[order_num];
            return (
              <ChatMessage
                me={role === "user"}
                key={index}
                enableFeedback={
                  role === "assistant" &&
                  (!responding || order_num + 1 !== allMessages.length)
                }
                feedbackHandlers={{
                  handleThumbs: async (thumb: "up" | "down") => {
                    authClient.post("/admire_chatbot/thumb", {
                      chat_id: await chat_id,
                      order_num,
                      thumb,
                    });
                  },
                  handleFeedback: async (feedback: string) => {
                    authClient.post("/admire_chatbot/feedback", {
                      chat_id: await chat_id,
                      order_num,
                      feedback,
                    });
                  },
                }}
              >
                {content}
              </ChatMessage>
            );
          })}
    </ChatContainer>
  );
};

export default ChatResults;
